/* toggle section */
.set--toggle {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1rem;
  font-size: 1.4rem;
  margin-top: 1rem;
}
.toggle--slider {
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 20px;
}
.set-slider {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: #b4b4b4;
}

.slide--thumb {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: var(--Light-color);
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}
/* input section */
.set--label {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 1rem;
}
.set--input-field {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  text-indent: 8px;
  border: 1px solid gray;
  border-radius: 2px;
}

@media screen and (min-width: 768px) {
  .set--toggle {
    width: 50%;
  }
  .set--label {
    width: 50%;
  }
}
@media screen and (min-width: 920px) {
  .set--toggle {
    width: 40%;
  }
  .set--label {
    width: 40%;
  }
}
@media screen and (min-width: 1280px) {
  .set--toggle {
    width: 30%;
  }
}
