.booking-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  position: relative;
  z-index: 34;
  color: rgb(89, 89, 89);
  background-color: rgb(243, 242, 241);
  font-family: "Poppins";

  .book-forms {
    width: 60%;
    margin-bottom: 3rem;
    .form-header {
      display: flex;
      justify-content: space-between;

      h1 {
        color: rgb(45, 45, 45);
        font-weight: 700;
        font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial,
          "Liberation Sans", Roboto, Noto, sans-serif;
        font-size: 1.75rem;
        line-height: 1.25;
        margin: 0px 0px 0.5rem;
        max-width: 350px;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }

    .form-desc1 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

.booking-sections::backdrop {
  background-color: rgb(255, 255, 255);
}

.bookform-texts {
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 0px;
  border-radius: 0.5rem;
  cursor: text;
  outline: none;

  border: 1px solid black;
  font-size: 1rem;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial,
    "Liberation Sans", Roboto, Noto, sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5;
  color: rgb(45, 45, 45);
  padding: 0.5625rem 0.75rem;
  min-height: 2.75rem;
  min-width: 0px;
}

.bookform-textarea {
  font-size: 1rem;
  height: 9rem;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  z-index: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  transition: box-shadow 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    border 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.jobform-desc {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 1.5rem;

  h6 {
    font-size: 0.875rem;
    line-height: 1.5;
    margin-top: 0.25rem;
    font-weight: 500;
    color: rgb(56, 55, 55);
  }
}

.form-mtop {
  margin-top: 2rem;
}

.form-box {
  background-color: rgb(255, 255, 255);
  border-radius: 1rem;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bookform-label {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    color 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  color: rgb(45, 45, 45);
  font-weight: 700;
  display: inline-block;
  padding: 0px;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
}



.button-wrapper {
  button {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    position: relative;
    margin-top: 0.9rem;
    margin-bottom: 0.3rem;
    padding: 11px 18px !important;
    background: var(--dim-black);
    border-radius: 6px;

    &:hover {
      background: var(--dimpink);
      color: var(--Light-color);
      transform: translateY(-10px);
    }
  }
}
@media screen and (max-width: 980px) {
  .booking-sections {
    .book-forms {
      width: 90%;
    }
  }
}
