.set_img-rapper {
  position: relative;
  background: #333;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.set_img-rapper img {
  width: 100%;
  height: 100%;
}
.set1_info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.set_mobile-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.set_desktop-text {
  display: none;
}

.set_detail-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.set1_input-container {
  width: 45%;
}
.set1_input-label {
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 20px;
}
.set1_input-field {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  text-indent: 8px;
  border: 1px solid gray;
  border-radius: 2px;
}
.set1_info-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 20px;
}
.set1_info-text-container li {
  border-bottom: 1px solid gray;
  text-transform: capitalize;
}
.bio-text {
  font-size: 18px;
  line-height: 1.8;
  text-transform: capitalize;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 1rem;
  border-radius: 5px;
}
@media screen and (max-width: 340px) {
  .set_img-rapper {
    height: 320px;
  }
  .set1_input-container {
    width: 46%;
  }
}

@media screen and (min-width: 768px) {
  .set_img-rapper {
    height: 400px;
  }
  .set_img-rapper img {
    width: 80%;
  }
  .set1_input-label {
    display: flex;
    flex-direction: column;
    align-items: left;
    font-size: 25px;
  }

  .set1_info-text-container {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 3rem;
  }
  .set1_info-text-container li {
    width: 45%;
  }
}
@media screen and (min-width: 920px) {
  .set_mobile-text {
    display: none;
  }
  .set_desktop-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
  }
  .set1_input-label {
    font-size: 1.4rem;
  }
  .set1_input-container {
    width: 45%;
  }
  .set_detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .set_img-rapper {
    width: 50%;
  }
  .set_img-rapper img {
    width: 100%;
  }

  .set1_info-section-rapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .set1_info-text-container {
    row-gap: 2rem;
  }
  .set1_info-text-container li {
    width: 85%;
  }
}
@media screen and (min-width: 920px) and (min-height: 850px) {
  .set_mobile-text {
    display: flex;
  }
  .set_desktop-text {
    display: none;
  }
  .set_detail-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .set_img-rapper {
    height: 500px;
    width: 100%;
  }
  .set_img-rapper img {
    width: 80%;
  }
  .set1_info-section-rapper {
    width: 100%;
  }
  .set1_info-text-container {
    row-gap: 3rem;
  }
  .set1_info-text-container li {
    width: 45%;
  }

  .set1_input-label {
    font-size: 1.8rem;
  }
  .set1_input-field {
    height: 50px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .set_img-rapper img {
    width: 85%;
  }
  .set_detail-container {
    gap: 2rem;
  }
  .info-section__rapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
