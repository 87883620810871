// global and it can't be inside scss nesting
.form-transition {
    transition: 1s cubic-bezier(0.4, 0.1, 0.4, 1) !important;
}

.signupform-contact .signupform-container > div {
    grid-column: 1/2;
    grid-row: 1/2;
}

// Scss Starts

section {
    &.Forms {
        width: 95%;
        margin: auto;
    }
    &.KycForms {
        width: 100%;
    }
    &.light-theme {
        --text-color: #000;
        --main-hue: 208;
        --main-hue-2: 331;
        --main-hue-3: 213;

        --form-transition: 1s cubic-bezier(0.4, 0.1, 0.4, 1) !important;
        --main-color-2: hsl(var(--main-hue), 92%, 54%);
        --main-color: hsl(var(--main-hue-2), 100%, 50%);
        --main-color-hover: hsl(var(--main-hue), 92%, 54%);
        --nav-color: hsl(var(--main-hue), 17%, 79%);
        --bg-color: #f9f9f9;
        --light-text-color: #9ca7b6;
        --light-main-color: hsl(var(--main-hue), 91%, 55%);
        --border-color: hsl(331.29deg 100% 50%);
        --main-color-change: hsl(331.29deg 100% 50%);
        --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
        --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
        --input-text: #d5d7da;
        --input-shadow: hsla(var(--main-hue), 92%, 54%, 0.169);
        --form-bg-opacity: 0;
        --checkbox-color: #f0f0f0;
        --form-bg-color: transparent;
        --transform-img: translate(-50%, -50%) scale(1.4);
        --icon-check-color: var(--main-color);
        --box-shadow-color: var(--main-hue-2);
    }

    &.dark-theme {
        --text-color: #fff;
        --checkbox-color: #f0f0f0f7;
        --box-shadow-color: var(--main-hue);
        --main-color-change: var(--light-main-color);
        --box-shadow: 0 0 0 5px hsla(var(--main-hue), 91%, 55%, 0.11);
        --bg-color: hsl(var(--main-hue-3), 35%, 6%);
        --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
        --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
        --nav-color: hsl(var(--main-hue), 10%, 47%);
        --light-text-color: hsl(var(--main-hue), 12%, 46%);
        --border-color: var(--light-main-color);
        --input-text: hsl(var(--main-hue), 10%, 55%);
        --input-shadow: hsla(var(--main-hue), 92%, 58%, 0.2);
        --form-bg-opacity: 1;
        --form-bg-color: hsla(var(--main-hue), 30%, 16.5%, 60%);
        --transform-img: translate(-50%, -50%) scale(1.7);
        --icon-check-color: #26ffed;
    }

    .form-background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: var(--form-bg-opacity);
        background-color: var(--form-bg-color);
    }

    header {
        background: var(--bg-color);
        .signupforms {
            position: relative;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 4;

            .signupform-container {
                max-width: 82rem;
                margin: 0 auto;
                padding: 0 1rem;

                display: flex;
                align-items: flex-end;

                ul {
                    display: flex;
                    list-style: none;
                    align-items: center;
                    margin: 0.5rem 0;

                    li {
                        .form-logo {
                            display: flex;
                            align-items: center;
                            margin-right: 3rem;

                            .form-images {
                                display: grid;
                                grid-template-columns: 1fr;
                                grid-template-rows: 1fr;
                                img {
                                    height: 35px;
                                    grid-column: 1/2;
                                    grid-row: 1/2;
                                    margin-right: 0.8rem;

                                    &.logo-forDark {
                                        opacity: 0;
                                    }
                                }
                            }

                            h2 {
                                font-size: 1.55rem;
                                margin-top: 2px;
                                padding-top: 4px;
                                color: var(--text-color);
                                display: flex;
                                flex-direction: column;
                            }

                            .logo-color-change {
                                color: var(--text-color);
                            }

                            .logo-color {
                                color: var(--main-color);
                            }

                            .logo-01 {
                            }

                            .logo-02 {
                                margin-top: -6px;
                                span {
                                    padding-left: 3px;
                                }
                            }
                        }

                        .formnav-link {
                            margin-left: 5.5rem;
                            padding: 0 1rem;
                            font-weight: 600;
                            font-size: 1rem;
                            color: var(--nav-color);
                            transition: 0.3s;
                            &:hover {
                                color: var(--main-color);
                            }

                            &.theme-toggle {
                                cursor: pointer;

                                .sun-icon {
                                    display: inline;
                                }

                                .moon-icon {
                                    display: inline;
                                }
                            }

                            .closeform-icon {
                                cursor: pointer;
                                font-size: 1.1rem;
                            }
                        }
                        .settings-navlink {
                            text-transform: capitalize;
                            padding: 0 2rem;
                            color: var(--nav-color);
                            transition: 0.3s;
                            cursor: pointer;
                            &:hover {
                                color: var(--main-color);
                            }
                        }
                    }
                }
            }
        }
    }

    main {
        background: var(--bg-color);
        .signupform-contact {
            width: 100%;
            overflow: hidden;

            .signupform-container {
                min-height: 700px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;

                .form-left {
                    width: 100%;
                    max-width: 80rem;
                    margin: 0 auto;
                    padding: 65px 1.5rem 0;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    align-items: center;
                    position: relative;
                    z-index: 3;

                    .form-left-wrapper {
                        max-width: 40rem;

                        .form-left-heading {
                            h1 {
                                font-weight: 600;
                                color: var(--text-color);
                                font-size: 3.5rem;
                                margin-bottom: 1.2rem;
                                line-height: 4.5rem;

                                span {
                                    color: var(--main-color);
                                }
                            }

                            h3 {
                                font-weight: 600;
                                color: var(--text-color);
                            }

                            .form-text {
                                color: var(--light-text-color);
                                line-height: 1.1;
                                font-size: 1rem;

                                a {
                                    color: var(--main-color);
                                    transition: 0.3s;
                                    font-weight: 700;
                                    &:hover {
                                        color: var(--main-color-hover);
                                    }
                                }
                            }
                        }

                        .form-left-form {
                            margin-bottom: 2rem;
                            .form-titles-wrapper {
                                position: relative;
                                width: 100%;
                                margin: 1.6rem 0;
                                .form-titles {
                                    text-align: center;
                                    text-transform: uppercase;
                                    color: var(--main-color);
                                }

                                .form-descriptions {
                                    padding: 1rem 0;
                                    svg {
                                        color: var(--main-color);
                                    }
                                    span {
                                        color: var(--text-color);
                                    }
                                }
                            }

                            .form-input-column {
                                display: flex;
                                column-gap: 20px;
                            }

                            .form-stats-column {
                                display: grid;
                                grid-template-columns: 50% 50%;
                                grid-column-gap: 20px;
                            }

                            .form-images-column {
                                display: grid;
                                grid-template-columns: 50% 50%;
                                grid-gap: 20px;
                            }

                            .form-categories-column {
                                .form-categories {
                                    position: relative;
                                    font-size: 15px;
                                    color: #222;
                                    display: inline-block;
                                    line-height: 40px;
                                    padding: 0 22px;
                                    background: var(--checkbox-color);
                                    border-radius: 7px;
                                    margin: 5px 5px 5px 0;
                                    transition: all 300ms linear 0ms;
                                    cursor: pointer;

                                    &:hover {
                                        background: var(--pink);
                                        color: white;
                                    }

                                    .input-checkbox {
                                        -webkit-appearance: none;
                                        appearance: none;
                                        width: 100%;
                                        appearance: none;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 34;
                                        height: 100%;
                                        cursor: pointer;
                                    }

                                    .checbox-active {
                                        background: var(--pink);
                                        color: white;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        cursor: pointer;
                                        width: 100%;
                                        z-index: 33;
                                        height: 100%;
                                        transition: all 300ms linear 0ms;
                                        border-radius: 7px;
                                        text-align: center;

                                        svg {
                                            align-self: center;
                                        }
                                    }
                                }
                            }

                            .form-container {
                                position: relative;
                                width: 100%;

                                .form-wrapper {
                                    position: relative;
                                    width: 100%;
                                    margin-top: 20px;
                                    &.w-100 {
                                        grid-column: span 2;
                                    }

                                    .read-only-wrapper {
                                        svg {
                                            color: var(--main-color-change) !important;
                                        }

                                        &:hover {
                                            svg {
                                                color: var(--input-text) !important;
                                            }
                                        }
                                    }

                                    .read-only-container {
                                        .read-only-title {
                                        }
                                        .read-only-infobox {
                                            position: relative;
                                            height: 50px;
                                            width: 100%;
                                            outline: none;
                                            font-size: 1rem;
                                            color: var(--text-color);
                                            margin-top: 8px;
                                            border: 1px solid #ddd;
                                            border-radius: 10px;
                                            padding: 0 15px;
                                            display: flex;
                                            align-items: center;
                                            background-color: var(--bg-color);
                                            border: 2px solid var(--border-color);
                                            box-shadow: 0 0 0 5px hsla(var(--box-shadow-color), 91%, 55%, 3%);

                                            &:hover {
                                                background-color: var(--bg-color);
                                                border: 1px solid #ddd;
                                                box-shadow: 0 0 0 5px hsla(var(--box-shadow-color), 91%, 55%, 3%);
                                            }

                                            .info {
                                            }
                                        }
                                    }

                                    .select-box {
                                        position: relative;
                                        height: 50px;
                                        width: 100%;
                                        outline: none;
                                        font-size: 1rem;
                                        color: #707070;
                                        margin-top: 8px;
                                        border: 1px solid #ddd;
                                        border-radius: 6px;
                                        padding: 0 15px;
                                        select {
                                            height: 100%;
                                            width: 100%;
                                            outline: none;
                                            border: none;
                                            color: #707070;
                                            font-size: 1rem;
                                            background-color: var(--input-bg);
                                            margin-left: 0 !important;
                                            font-weight: initial;
                                        }
                                    }

                                    .input-textarea {
                                        position: relative;
                                        border: 2px solid transparent;
                                        box-shadow: 0 0 0 0px var(--input-shadow);
                                        width: 100%;
                                        background-color: var(--input-bg);
                                        border: none;
                                        padding: 1.5rem 1.35rem calc(0.75rem - 2px) 1.35rem;
                                        outline: none;
                                        border: none;
                                        font-family: inherit;
                                        border-radius: 20px;
                                        color: var(--text-color);
                                        font-weight: 600;
                                        font-size: 0.95rem;
                                        transition: 0.3s;

                                        &:hover {
                                            background-color: var(--input-bg-hover);
                                        }

                                        &.read-only-textarea {
                                            background-color: var(--bg-color);
                                            border: 2px solid var(--border-color);
                                            box-shadow: 0 0 0 5px hsla(var(--box-shadow-color), 91%, 55%, 3%);
                                            &:hover {
                                                border: 2px solid transparent;
                                                box-shadow: 0 0 0 0px var(--input-shadow);

                                                background-color: var(--input-bg);
                                            }
                                        }
                                    }

                                    label {
                                        position: absolute;
                                        top: 50%;
                                        left: calc(1.35rem + 2px);
                                        transform: translateY(-50%);
                                        color: var(--light-text-color);
                                        pointer-events: none;
                                        transition: 0.25s;
                                    }

                                    svg {
                                        position: absolute;
                                        right: calc(1.35rem + 2px);
                                        top: 50%;
                                        transform: translateY(-50%);
                                        pointer-events: none;
                                        color: var(--input-text);
                                        font-size: 1.25rem;
                                        transition: 0.3s;
                                    }

                                    textarea {
                                        resize: none;
                                        width: 100%;
                                        min-height: 150px;
                                        ~ label {
                                            top: 1.2rem;
                                            transform: none;
                                        }

                                        ~ svg {
                                            top: 1.3rem;
                                            transform: none;
                                        }
                                    }

                                    &.focus {
                                        .input-textarea {
                                            background-color: var(--bg-color);
                                            border: 2px solid var(--border-color);
                                            box-shadow: 0 0 0 5px hsla(var(--box-shadow-color), 91%, 55%, 3%);
                                        }

                                        label {
                                            color: var(--main-color-change) !important;
                                        }

                                        svg {
                                            color: var(--main-color-change) !important;
                                        }
                                    }

                                    &.not-empty {
                                        label {
                                            font-size: 0.66rem;
                                            top: 0.75rem;
                                            transform: translateY(0);
                                        }
                                    }
                                }
                                .form-error-controller {
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 0.5rem;
                                    gap: 0.5rem;
                                    transition: 0.3s;

                                    &.error-mtop {
                                        margin-top: 0;
                                        gap: 0;
                                    }

                                    .form-error-btn {
                                        svg {
                                            position: absolute;
                                            margin-top: -1.8rem;
                                            margin-left: 6px;
                                        }

                                        .errors {
                                            display: none;
                                        }
                                    }

                                    .form-error-text {
                                        font-family: inherit;

                                        color: var(--main-color);
                                        font-weight: 600;
                                        font-size: 0.6rem;
                                    }
                                }
                            }

                            .form-check-box {
                                position: relative;
                                width: 94%;
                                display: flex;
                                margin: 0 auto;
                                flex-direction: column;
                                gap: 0.5rem;
                                font-family: inherit;
                                font-size: 0.7rem;

                                .check-box-wrapper {
                                    display: flex;
                                    column-gap: 0.4rem;

                                    input {
                                        accent-color: var(--icon-check-color);
                                    }

                                    label {
                                        color: var(--text-color);
                                        &:hover {
                                            color: var(--main-color) !important;
                                        }
                                    }
                                }

                                p {
                                    color: var(--main-color);
                                    font-weight: 600;
                                }
                            }

                            .form-buttons {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                column-gap: 1rem;
                                margin-top: 1rem;
                                width: 100%;
                                grid-column: span 2;

                                .form-btn {
                                    display: inline-block;
                                    padding: 1.1rem 2rem;
                                    background-color: var(--main-color);
                                    color: #fff;
                                    border-radius: 40px;
                                    border: none;
                                    font-family: inherit;
                                    font-weight: 500;
                                    font-size: 1rem;
                                    cursor: pointer;
                                    transition: 0.3s;

                                    :hover {
                                        background-color: var(--main-color-hover);
                                    }

                                    &.progress-btn {
                                        height: 100%;
                                        width: 60%;
                                    }
                                }
                            }
                        }
                    }
                }

                .form-right {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    overflow: hidden;
                    pointer-events: none;

                    .form-img-wrapper {
                        position: relative;
                        grid-column: 2;

                        img {
                            position: absolute;
                            height: 110%;
                            left: 70%;
                            top: 60%;

                            transform: var(--transform-img);
                        }
                    }
                }
            }
        }
    }

    &.dark-theme {
        .form-background {
            opacity: var(--form-bg-opacity);
            background-color: var(--form-bg-color) !important;
        }
    }

    &.dark-theme header {
        .signupforms {
            .signupform-container {
                ul {
                    li {
                        .form-logo {
                            .form-images {
                                img {
                                    &.logo-forDark {
                                        opacity: 1 !important;
                                    }
                                }
                            }
                        }

                        .form-logo {
                            .form-images {
                                img {
                                    &.logo-forLight {
                                        opacity: 0 !important;
                                    }
                                }
                            }
                        }

                        .formnav-link {
                            &.theme-toggle {
                                .sun-icon {
                                    display: inline;
                                }

                                .moon-icon {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Scss Ends

@media (max-width: 1000px) {
    section {
        header {
            .signupforms {
                .signupform-container {
                    ul {
                        li {
                            .form-logo {
                                margin-right: 1rem;

                                .form-images {
                                    img {
                                        height: 30px;
                                        margin-right: 0.7rem;
                                    }
                                }

                                h2 {
                                    font-size: 1.3rem;
                                }
                            }

                            .formnav-link {
                                margin-left: 3.5rem;
                                padding: 0 0.8rem;
                                font-size: 0.9rem;
                                color: var(--nav-color);
                                transition: 0.3s;
                                &:hover {
                                    color: var(--main-color);
                                }
                            }
                        }
                    }
                }
            }
        }

        main {
            .signupform-contact {
                .signupform-container {
                    .form-left {
                        .form-left-wrapper {
                            .form-left-heading {
                                h1 {
                                    font-size: 2.5rem;
                                    white-space: normal;
                                    margin-bottom: 1rem;
                                }

                                .form-text {
                                    font-size: 0.9rem;
                                }
                            }

                            .form-left-form {
                                .form-input-column {
                                }
                                .form-container {
                                    .form-wrapper {
                                        .input-textarea {
                                            padding: 1.5rem 1.35rem calc(0.75rem - 2px) 1.35rem;
                                            border-radius: 17px;
                                            font-size: 0.87rem;
                                        }

                                        label {
                                            left: calc(1.2rem + 2px);
                                            font-size: 0.91rem;
                                        }

                                        svg {
                                            font-size: 1.1rem;
                                            right: calc(1.2rem + 2px);
                                        }

                                        textarea {
                                            ~ label {
                                                top: 1.2rem;
                                            }

                                            ~ svg {
                                                top: 1.33rem;
                                            }
                                        }

                                        &.focus {
                                            .input-textarea {
                                            }

                                            label {
                                                font-size: 0.61rem;
                                            }
                                        }
                                    }
                                }

                                .form-buttons {
                                    column-gap: 0.8rem;
                                    margin-top: 0.45rem;

                                    .form-btn {
                                        padding: 1.1rem 1.5rem;

                                        font-size: 0.87rem;
                                    }
                                }
                            }
                        }
                    }

                    .form-right {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        overflow: hidden;
                        pointer-events: none;

                        .form-img-wrapper {
                            position: relative;
                            grid-column: 2;

                            img {
                                position: absolute;
                                height: 130%;
                                left: 60%;
                                top: 50%;

                                transform: var(--transform-img);
                            }
                        }
                    }
                }
            }
        }
    }
}

// regulates the grid columns to stack on eachother
@media (max-width: 1000px) and (min-width: 650px) {
    .form-left {
        grid-template-columns: none !important;
    }
    .form-img-wrapper {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    }
}

@media (max-width: 650px) {
    section {
        .form-background {
            display: none;
        }
        header {
            .signupforms {
                .signupform-container {
                    ul {
                        justify-content: space-between;
                        width: 100%;
                        margin: 1rem 0 0.3rem 0 !important;
                        li {
                            .form-logo {
                                margin-right: 0;

                                h2 {
                                    font-size: 1.3rem;
                                }
                            }

                            .formnav-link {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }

        main {
            .signupform-contact {
                .signupform-container {
                    .form-left {
                        padding: 50px 1.5rem 0;
                        grid-template-columns: 1fr;
                        justify-content: center;

                        .form-left-wrapper {
                            width: 100%;
                            padding: 1rem 0;
                            .form-left-heading {
                                h1 {
                                    line-height: 2.5rem;
                                }

                                .form-text {
                                }
                            }

                            .form-left-form {
                                .form-container {
                                    .form-wrapper {
                                        .input-textarea {
                                        }

                                        label {
                                        }

                                        svg {
                                        }

                                        textarea {
                                            ~ label {
                                            }

                                            ~ svg {
                                            }
                                        }

                                        &.focus {
                                            .input-textarea {
                                            }

                                            label {
                                            }
                                        }
                                    }
                                }

                                .form-buttons {
                                    .form-btn {
                                    }
                                }
                            }
                        }
                    }

                    .form-right {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 557px) {
    section {
        header {
            .signupforms {
                .signupform-container {
                    ul {
                        li {
                            .form-logo {
                                img {
                                    height: 29px;
                                    margin-right: 0.7rem;
                                }
                                h2 {
                                    font-size: 1.15rem;
                                }
                            }

                            .formnav-link {
                            }
                        }
                    }
                }
            }
        }

        main {
            .signupform-contact {
                .signupform-container {
                    .form-left {
                        .form-left-wrapper {
                            .form-left-heading {
                                h1 {
                                    font-size: 1.9rem;
                                }

                                .form-text {
                                }
                            }

                            .form-left-form {
                                .form-input-column {
                                    display: flex;
                                    flex-direction: column;
                                    column-gap: 20px;
                                }

                                .form-stats-column {
                                    display: flex;
                                    flex-direction: column;
                                    column-gap: 20px;
                                }
                                .form-images-column {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 20px;
                                }
                                .form-container {
                                    .form-wrapper {
                                        .input-textarea {
                                        }

                                        label {
                                        }

                                        svg {
                                        }

                                        textarea {
                                            ~ label {
                                            }

                                            ~ svg {
                                            }
                                        }

                                        &.focus {
                                            .input-textarea {
                                            }

                                            label {
                                            }
                                        }
                                    }
                                }

                                .form-buttons {
                                    grid-template-columns: 1fr;
                                    row-gap: 0.5rem;
                                    .form-btn {
                                    }
                                }
                            }
                        }
                    }

                    .form-right {
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .dots-hide-on-mobile {
        display: none !important;
    }
}
