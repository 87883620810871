.Testimonial-container {
  margin-top: 4rem;
}

.Testimonial-container .Testimonial-left {
  width: 35%;
  padding: 35px;
  margin: 20px;
}

.Testimonial-container h2 {
  margin: 15px 0 0 0;
}

.Testimonial-container .slide_details span {
  color: var(--dimpink);
}

.Testimonial-container .Testimonial-right {
  width: 65%;
  padding: 0 0 0 50px;
  margin: 0 20px 0 0;
}

.Testimonial-container .Testimonial-left div img {
  width: 100%;

  object-fit: cover;
  border-radius: 10px;
}

.Testimonial-container .slide_quota svg {
  font-size: 150px;
  opacity: 0.1;
}

.Testimonial-container .slide_content {
  padding: 50px;
}

.Testimonial-container .slide_content h1 {
  font-weight: 500;
}

.Testimonial-container .slide_content h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}

.Testimonial-wrapper {
  position: relative;
}

.Testimonial-wrapper > .Testimonialslide_btn {
  position: absolute;
  top: 30px;
  right: 20px;
  /* display: flex;
  gap: 0.5rem; */
}

.Testimonial-wrapper > .Testimonialslide_btn > .prev_btn {
  margin-right: 25px;
}

/* Slides */

.Testimonial-wrapper {
  margin: auto;
  height: 600px;
  position: relative;
  display: flex;
  overflow: hidden;
}

#Main_slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 1s linear;
}

#Main_slide.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

#Main_slide.lastSlide {
  transform: translateX(-100%);
}

#Main_slide.nextSlide {
  transform: translateX(100%);
}

/* Slides */
/*
@media screen and (max-width: 768px) {
  .Testimonial-left,
  .Testimonial-right {
    width: 100%;
  }

  #Main_slide {
    flex-direction: column;
    padding: 20px;
  }

  .Testimonial-container .Testimonial-left {
    width: 100%;
    display: flex;
    margin: 0px;
  }

  .Testimonial-container .Testimonial-left img {
    width: 190px;
    border-radius: 10px;
    margin-right: 20px;
  }

  .Testimonial-container .Testimonial-right {
    width: 100%;
    margin: 0 0 3rem 0;
    padding: 0 0 0 0;
  }

  .Testimonial-wrapper {
    height: 70vh;
  }

  .Testimonial-container .slide_quota,
  .slide_quota svg {
    display: none;
  }
} */

.Testimonial-btn {
  padding: 14px 21px;
}

@media screen and (max-width: 780px) {
  .Testimonial-left,
  .Testimonial-right {
    width: 100%;
  }

  #Main_slide {
    flex-direction: column;
    justify-content: center;

    /* padding: 20px; */
  }

  .Testimonial-container .Testimonial-left {
    width: 87%;
    display: flex;
  }

  .Testimonial-container .Testimonial-right {
    width: 95%;
    padding: 0 0 0 50px;
    margin: 0 -1.8rem;
  }

  .slide_details {
    margin-top: 2rem;
  }

  .Testimonial-container .slide_quota,
  .slide_quota {
    display: none;
  }

  .Testimonial-container .slide_content {
    padding: 2px 35px 2px 35px;
    margin-top: 1rem;
  }

  .Testimonial-container .Testimonial-left div img {
    width: 80%;
  }
}


