/* Header Section begins */

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  color: var(--white);
  background: #2e313d;
  background-image: url(../../../public/images/team/img3-cut.png);
  background-position: 50% 50%;
  background-size: cover;
  background-blend-mode: overlay;
  transition: 5s;
  animation-name: animate;
  animation-direction: alternate-reverse;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  width: 100%;
}

@keyframes animate {
  0% {
    background-image: url(../../../public/images/featured/slider10.jpg);
  }

  20% {
    background-image: url(../../../public/images/featured/slider2.jpg);
  }

  40% {
    background-image: url(../../../public/images/featured/slider18.jpg);
  }

  60% {
    background-image: url(../../../public/images/how-it-works/model2.png);
  }

  80% {
    background-image: url(../../../public/images/featured/slider12.jpg);
  }

  100% {
    background-image: url(../../../public/images/featured/slider9.jpg);
  }
}
.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.header-section h1 {
  font-size: 45px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: var(--pink);
  filter: brightness(100%);
}

.header-section h1::selection {
  color: rgb(238, 210, 255);
}

.header-section span {
  font-size: var(--font-size-m);
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.header-section span:nth-of-type(2) {
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-white);
}

.about-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6rem;
  margin-top: 4rem;
  overflow: hidden;

  padding: 2rem;
  box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%),
    0px 6px 4px rgb(92 78 78 / 25%);
  border-radius: 10px;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;

  background-image: url(../../../public/images/home/about-section.jpg);
}

.about-section {
  display: flex;
  align-items: center;
  gap: 3.5rem;
  position: relative;

  background: white;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 11%);
  border-radius: 10px;
  padding: 2rem;
}

.about-section img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.about-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.about-text span {
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #1e202c;
}

/* Team Section Begins */
.team-container {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;
}

/* Team Section ends */

/* Commuinty Section Begins */

/* Booking Begins */

.booking-wrapper {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: rgb(248, 248, 248) inset;

  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px 5px rgba(40, 40, 46, 0.1),
    0px 6px 4px rgba(92, 78, 78, 0.25);

  border-radius: 10px;
  background-position: 50% 50%;
  background-image: url(../../../public/images/about/color-splash.png);
}

.booking-desc {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  position: relative;
}

.booking-desc p {
  text-align: center;
}

.booking-desc h2 {
  text-align: center;
  font-size: 30px;
}

/* Booking Ends */

/* Footer Sections */

@media screen and (max-width: 767px) {
  .header-section {
    padding: 0 7px;
  }

  .about-section {
    display: flex;
    flex-direction: column;
  }

  .about-section.section-2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-section img {
    border-radius: 20px;
    width: 94%;
    height: 94%;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
  }

  .about-text span {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;

    color: #1e202c;
  }

  /* Blur Effect */
}

.thankyoupage-container {
  background: 
  linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../../../public/images/bg-image/lg_bg.jpeg");
  background-position: 50% 7% ;
  background-size: cover;
  height: 380px;
  position: relative;

        width: 100%;
        
        
        overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url('../../../public/images/about/thankyou.jpg');
    background-position: 50% 50% ;
    background-size: cover;
    bottom: -13%;
    right: 10%;
    transform: translateY(-70%);
    z-index: 6;
    transition: 1.8s ease-in-out;
   
    

}
  
}

.thankyou-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  height: 347px;
}

.thankyou-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  flex: 8;
  z-index: 10;
}

.thankyou-text h2 {
  font-size: 50px;
  text-align: center;
  color: white;
  font-weight: 800;
}

.thankyou-image {
  flex: 3;
 
  
}

.thankyou-image img {
  width: 100%;
  height: 340px;
  object-fit: cover;
}

@media screen and (max-width: 500px) {

    .thankyoupage-container {
   height: 400px;
    &::before {
      content: "";
   
    
      width: 140px;
      height: 140px;
      bottom: 5%;
      right: 10%;
      transform: translate(-160%);
     
     
      
  
  }


    
  }
  .thankyou-text {
    flex: 4;
    padding-left: -7px;
  }

  .thankyou-image {
    flex: 4;
  }
  .thankyou-text h2 {
    font-size: 32px;
  }
}


@media screen and (max-width: 1314px) {
  .thankyoupage-container {
   
    &::before {
      content: "";
   
      width: 150px;
      height: 150px;
      bottom: 6%;
      right: 10%;
      transform: translateY(-70%);
     
     
      
  
  }
    
  }
}
@media screen and (max-width: 800px) {
  .thankyoupage-container {
   height: 400px;
    &::before {
      content: "";
   
    
      width: 140px;
      height: 140px;
      bottom: 5%;
      right: 10%;
      transform: translate(-160%);
     
     
      
  
  }


    
  }

  .thankyou-text h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 700px) {
  .thankyoupage-container {
  
    &::before {
      content: "";
   
    
      width: 140px;
      height: 140px;
      bottom: 20%;
      right: 9%;
      transform: translate(-10%);
     
     
      
  
  }


    
  }

  .thankyou-text h2 {
    font-size: 35px;
  }
}

