.btn1:hover {
  background: #ccf1f8;
  color: black;
}

.faq-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  position: relative;
}

.faq-article {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.faq-article ul {
  margin-top: 1rem;
  padding-left: 2rem;
}

.faq-article p,
ul,
li {
  font-weight: 500;
  font-size: var(--font-size-s);
  letter-spacing: 0.5px;
}

.faq-article ul li {
  list-style: disc;
  padding-bottom: 1rem;
}

.faq-article h1 {
  font-weight: 700;
  font-size: var(--font-size-xl);
  text-align: center;
  color: black;
}

.faq-article h2,
h3 {
  color: var(--dim-black);
}

/* FAQ COMPONENT */

.faqs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.faqs h2 {
  width: 100%;
  padding: 0 39px;

  margin-top: 4rem;
  color: var(--dim-black);
  text-align: center;
  font-size: var(--font-size-l);
}

.faqsquestion__wrapper {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 2.5rem;
  align-items: flex-start;
}

.faqs-question {
  padding: 2rem;

  box-shadow: 4px 4px 8px rgba(212, 127, 169, 0.1),
    4px -4px 8px rgba(127, 151, 165, 0.25);

  border-radius: 10px;
  cursor: pointer;
}

.faqs-question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqs-question-title .faq__icon {
  border: none;
  outline: none;
  background: transparent;

  font-size: 1.2rem;
}

.faqs-question-title h4 {
  font-size: 1rem;
  line-height: 2.2;
}

.faqs-question p {
  margin-top: 1.5rem;
}

/* Blur */

.blur {
  background: rgb(238, 210, 255);
  position: absolute;
  width: 22rem;
  height: 1rem;
  border-radius: 50%;

  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

.blur-2 {
  background: #c1f5ff;
  position: absolute;
  width: 21rem;
  height: 11rem;
  border-radius: 50%;

  z-index: -9;

  left: -5rem;
  top: 22%;
  filter: blur(73px);
}

.blur-2-right {
  background: rgb(255, 224, 240);
  left: 72%;
  position: absolute;
  filter: blur(78px);
  width: 22rem;
  height: 11rem;
  top: 70%;
  z-index: -44;
}

.blur-22 {
  background: rgb(255, 210, 233);
}

.blr-11 {
  background: #d0f1f8;
}

.blr-1 {
  background: rgb(255, 210, 233);
  /* background: rgba(255, 0, 122, 1); */
  top: 80%;
}

.br3 {
  left: 70%;
  background: rgb(238, 210, 255);
}

@media screen and (max-width: 1024px) {
  .blur,
  .blur-2,
  .blur-2-right,
  .blur-22,
  .blr-11,
  .blr-1 {
    display: none;
  }

  .faqsquestion__wrapper {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 2.5rem;
    align-items: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .blur,
  .blur-2,
  .blur-2-right,
  .blur-22,
  .blr-11,
  .blr-1 {
    display: none;
  }

  .faqsquestion__wrapper {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 2.5rem;
    align-items: flex-start;
  }
}
