@import "../../scss/mixins";
@import "../../scss/color";
@import "../../scss/utils";

#img_card {
  width: 240px;
  max-height: 340px;
  border-radius: 0.1em;
  overflow: hidden;

  @include mq($width: 1050px) {
    width: 100%;
    border-radius: 0.3em;
  }
}

#img_card:hover#img_card > img {
  transform: scale(1.1);
}

#img_card > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: all 0.6s ease-out;
}
