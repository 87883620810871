.contact-head {
  max-width: 800px;
  text-align: center;
}

.contact-head h1 {
  color: var(--pink);
}

.contact-head p {
  font-size: 16px;
  font-weight: 500;

  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
}
