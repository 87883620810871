.wallet-form {
  width: 95%;
  padding: 1.5rem;
  background: var(--Colored-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.wallet-form-top-text {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
  color: var(--Light-color);
  position: relative;
}
.wallet-form-top-text h3 {
  color: var(--Light-color);
}
.wallet-form-top-text i {
  cursor: pointer;
}

fieldset {
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 0rem 0.8rem;
}
legend {
  padding: 5px;
  font-size: 1.3rem;
  color: var(--Light-color);
  font-weight: 500;
}

.label-1 {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--Light-color);
}

.label-1 input {
  width: 80%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  text-indent: 8px;
}
.label-1-text {
  color: var(--Colored-color);
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 10px;
}

.label-2 {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.label-2 input {
  height: 30px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  text-indent: 8px;
}
.label-2 label {
  font-size: 1rem;
  color: var(--Light-color);
  font-weight: 500;
}
.bank-option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  width: 100%;
}
.bank-avatar {
  width: 33px;
  height: 30px;
}
.bank-avatar img {
  border-radius: 2px;
  border: 1px solid var(--Light-color);
  width: 100%;
  height: 100%;
}

.bank-option select {
  font-size: 1.2rem;
  font-weight: 500;
  min-height: 30px;
  border-radius: 0;
  padding: 0 0.8rem;
  margin-left: 0;
  color: gray;
  background: var(--Light-color);
}

.remark {
  width: 100%;
  resize: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 2px 8px;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 1rem 0;
}

.button-container p {
  font-size: 13px;
  color: var(--Light-color);
}
.send-button {
  border-radius: 30px;
  padding: 0.6rem 0;
  width: 80%;
  font-size: 1.3rem;
  font-weight: 600;
  align-self: center;
}
.send-button:hover {
  cursor: pointer;
}
.contact-link {
  color: var(--Light-color);
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}
.btm-margin {
  margin-bottom: 4px;
}
.withdraw-balance {
  width: 70%;
  padding: 1rem;
  color: var(--Dark-color);
  background: var(--Light-color);
  font-size: 1.2rem;
  border-radius: 5px;
}
.withdraw-balance p {
  font-weight: 600;
  margin: 10px 0;
}

@media screen and (max-height: 800px) {
  .wallet-form {
    margin-top: 25rem;
  }
}

@media screen and (max-height: 800px) {
  .fund-form {
    margin-top: 0rem;
  }
}

@media screen and (max-width: 345px) {
  .withdraw-balance h4 {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .wallet-form {
    width: 70%;
    padding: 2rem;
  }
}
@media screen and (min-width: 920px) {
  .wallet-form {
    width: 55%;
  }
}
@media screen and (min-width: 1050px) {
  .wallet-form {
    width: 40%;
  }
}
@media screen and (min-width: 1230px) {
  .wallet-form {
    max-width: 500px;
  }
}
