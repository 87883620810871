// SVG Effect (i used css format instead of scss format and moved to a diffrent filefor clarity purpose) no special requirement

.wave-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    background-color: var(--bg-color);
    filter: blur(0px);
}

.wave-wrap svg {
    position: absolute;
    height: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%) scaleX(1);
    left: calc(100% - 2px);
    transform-origin: left;
}

#wave {
    fill: var(--bg-color);
    height: 100%;
}

.dashed-wave {
    position: absolute;
    z-index: 3;
    height: 130%;
    bottom: 60%;
    left: -28px;
    transform: translateY(50%);
    opacity: 0.5;
}

#dashed-wave {
    fill: none;
    stroke: var(--main-color);
    stroke-width: 1px;
    stroke-dasharray: 6.5;
    opacity: 0.3;
}

section.dark-theme #dashed-wave {
    opacity: 0.2;
}

section.dark-theme .wave-wrap {
    transform: scale(1.8);
    filter: blur(55px);
}

section.dark-theme .wave-wrap svg {
    transform: translateY(-50%) scaleX(0);
}

@media (max-width: 1000px) {
    .dashed-wave {
        bottom: 55%;

        left: -10px;
    }

    #dashed-wave {
        stroke-width: 0.7px;
        stroke-dasharray: 6;
    }

    .wave-wrap {
        transform: translateX(-10%);
    }

    .wave-wrap svg {
        height: calc(110% + 10px);
        transform: translate(-5%, -50%);
    }
}
