/* ................ NAVBAR ........... */
.nav {
  position: relative;
  box-shadow: 0px 10px 20px 5px rgba(40, 40, 46, 0.1),
    0px 6px 4px rgba(92, 78, 78, 0.25);
  padding-top: 1rem;
  padding-bottom: 0.6rem;
}

.nav__container {
  background: var(--white);
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}
.logo {
  width: 40%;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.4);
  transform: translateX(-100%);
  transition: all 0.2s ease-out;
}
.mobile-nav__container {
  width: 75%;
  height: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url("./img/bg-1.jpg");
  background-position: 50% 50%;
  background-size: cover;
  color: var(--white);
  transform: translateX(-100%);
}
.mobile-nav__list {
  padding: 0 2rem;
  padding-top: 6rem;
  padding-bottom: 4rem;
}
.mobile-nav__item {
  padding: 1rem 0;
  text-align: left;
  font-size: 1.2rem;
  transition: all 0.4s ease-in-out;
}
.mobile-nav__item:hover {
  cursor: pointer;
  color: rgba(255, 0, 122, 1);
}
.mobile-nav__btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.btn {
  font-family: var(--font-family-poppins);
  font-weight: 600;
  font-size: var(--font-size-s);
}
.btn1 {
  padding: 12px 30px;
  background: var(--btn1-background);
  box-shadow: var(--btn1-box-shadow);
  border-radius: var(--btn1-border-radius);
  color: var(--light-black);
  transition: all 0.4s ease-in-out;
}

.btn2 {
  padding: 12px 20px;
  background: var(--pink);
  box-shadow: var(--btn2-box-shadow);
  border-radius: var(--btn2-border-radius);
  color: var(--white);
  transition: all 0.4s ease-in-out;
}

.btn2:hover {
  cursor: pointer;
  background: var(--btn1-background);
  color: rgba(255, 0, 122, 1);
}

.btn1:hover {
  cursor: pointer;
  background: var(--dim-black);
  color: white;
}

.close-menu {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
  transition: all 0.4s ease-in-out;
}
.open-menu {
  font-size: var(--font-size-l);
  transition: all 0.4s ease-in-out;
}
.open-menu:hover,
.close-menu:hover {
  color: var(--Colored-color);
  cursor: pointer;
}
.desktop-nav,
.desktop-nav__btn {
  display: none;
}

@media screen and (max-width: 415px) {
  .mobile-nav__container {
    background-position: 30% 50%;
    background-size: cover;
  }
}
@media screen and (max-width: 320px) {
  .mobile-nav__list {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
  .mobile-nav__item {
    font-size: 1rem;
  }
  .btn1 {
    padding: 12px 25px;
  }

  .btn2 {
    padding: 12px 18px;
  }
}
@media screen and (min-width: 768px) {
  /* header sections and nav  */
  .nav__container {
    width: 90%;
  }
  .nav {
    padding: 2rem 0;
  }

  .logo {
    width: 20%;
  }
  .desktop-nav {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desktop-nav__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    font-size: var(--font-size-s);
    font-weight: 600;
  }

  .desktop-nav__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .mobile-nav {
    display: none;
  }
  .open-menu {
    display: none;
  }

  /* the first design */

  .desktop-nav li a {
    transition: var(--transition);
  }

  .desktop-nav li a:hover {
    color: var(--pink);
  }

  .active-nav {
    position: relative;
  }

  .active-nav::after {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--pink);
    /* left: calc(50% - 0.6rem); */
    /* transform: rotate(45deg); */
    margin-top: 0.6rem;
  }

  .btn1 {
    padding: 12px 25px;
  }
}
@media screen and (min-width: 920px) {
  .logo {
    width: 15%;
  }
  .desktop-nav__list {
    font-size: 18px;
  }
  .btn1 {
    padding: 15px 30px;
  }

  .btn2 {
    padding: 15px 25px;
  }
}
@media screen and (min-width: 1280px) {
  .logo {
    width: 15%;
  }
  .desktop-nav__list {
    gap: 2rem;
    font-size: 20px;
  }
  .btn1 {
    padding: 17px 36px;
  }

  .btn2 {
    padding: 17px 36px;
  }
}
