.sidebar-container {
    left: 0;
    position: relative;
    max-width: 250px;
    z-index: 102;
    background: var(--bg-color);

    &.dark {
        left: 0;
        position: relative;
        z-index: 102;
        background: var(--bg-color);
    }

    &#close-sidebar {
        width: 78px !important;
    }

    .sidebar-menu {
        padding: 10px 14px;
        background: var(--bg-color);
        z-index: 100;
        height: 100vh;
        &.close {
            width: 78px !important;
        }

        header {
            position: relative;
            .sidebar-image {
                min-width: 60px;
                display: flex;
                align-items: center;
            }

            .sidebar-text {
                font-size: 16px;
                font-weight: 600;
                color: var(--text-color);
                transition: var(--trans-03);
                white-space: nowrap;

                &.close {
                    opacity: 0;
                }
            }

            .sidebar-toggle {
                position: absolute;
                top: 50%;
                right: -25px;
                height: 25px;
                width: 25px;
                background: var(--primary-color);
                transform: translateY(-50%) rotate(180deg);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: var(--toggle-arrow-color);
                cursor: pointer;
                transition: var(--tran-03);
                z-index: 3444;

                &.toggle-close {
                    transform: translateY(-50%);
                }

                &#toggle-dark {
                }

                &#toggle-close {
                    transform: translateY(-50%) !important;
                }
            }
        }

        li {
            height: 50px;

            margin-top: 10px;
            list-style: none;
            display: flex;
            align-items: center;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                min-width: 60px;
                color: var(--text-color);
                transition: var(--trans-02);
                cursor: pointer;
            }

            .sidebar-text {
                color: var(--text-color);
                transition: var(--trans-02);
                &.close {
                    display: none;
                }
            }

            .link-item {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                border-radius: 6px;
                text-decoration: none;
                transition: var(--trans-04);

                &:hover {
                    background: var(--primary-color);
                    .icon,
                    .sidebar-text {
                        color: var(--sidebar-color);
                        &.close {
                            display: none;
                        }
                    }
                }
            }
        }

        .sidebar-info-wrapper {
            display: flex;
            align-items: center;
            .sidebar-image {
                img {
                    width: 40px;
                    border-radius: 6px;
                }
            }

            .header-text {
                display: flex;
                flex-direction: column;

                .name {
                    font-weight: 600;
                }
                .title {
                    margin-top: -2px;
                }
            }
        }

        .main-links {
            height: calc(100% - 50px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100% !important;
            overflow: hidden;
            .top-content {
                .nav-search-box {
                    background: var(--primary-color-light);
                    border-radius: 6px;
                    input {
                        height: 100%;
                        width: 100%;
                        border: none;
                        outline: none;
                        background: var(--primary-color-light);
                        border-radius: 6px;
                    }
                }
            }
            .bottom-content {
                margin-top: 2rem;
                .mode {
                    position: relative;
                    border-radius: 6px;
                    background: var(--primary-color-light);

                    .moon-sun {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        transition: var(--trans-04);

                        svg {
                            transition: var(--trans-04);
                        }
                    }

                    .toggle-switch {
                        height: 100%;
                        min-width: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: absolute;
                        right: 0;
                        border-radius: 6px;
                        background: var(--primary-color-light);

                        .switch {
                            position: relative;
                            height: 22px;
                            width: 44px;
                            border-radius: 25px;
                            background: var(--toggle-color);
                            &::before {
                                content: "";
                                position: absolute;
                                height: 15px;
                                width: 15px;
                                border-radius: 50%;
                                top: 50%;
                                left: 5px;
                                transition: var(--tran-03);
                                transform: translateY(-50%);
                                background: var(--sidebar-color);
                            }

                            &.dark-switch {
                                &::before {
                                    left: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dashboard-home {
        position: relative;

        transition: var(--trans-05);

        &.close {
        }
    }
}

@media screen and (min-width: 1024px) {
    .close-link {
        display: none !important;
    }
}
