.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;

    .pics {
        transition: 0.7s ease-in-out;
        cursor: pointer;
        margin-bottom: 12px;

        img {
            border-radius: 20px;
            transition: 0.7s ease-in-out;
        }

        &:hover img {
            transform: scale(0.98);
        }

        .hover-state {
            cursor: pointer;
            color: #fff;
            background: var(--pink);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            border-radius: 10px;
            border: none;
            font-weight: 600;
            font-size: 0.7rem;
            padding: 8px;
            transition: 1.1s 0.4s ease-in-out;
            position: absolute;
            bottom: 7px;
            z-index: 300;
            transform: scale(0.98);
        }

        .preview-img {
            left: -2px;
            transition: 0.2s ease-in-out;
            &:hover {
                transform: scale(0.9);
            }
        }

        .delete-img {
            right: -2px;
            background: var(--dim-black);
            transition: 0.2s ease-in-out;

            &:hover {
                transform: scale(0.9);
            }
        }

        &:hover {
            filter: drop-shadow(0.3px 0.9px 1.5px rgba(255, 0, 123, 0.785));
        }

        &:hover::before {
            content: attr(data-img);
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 98.6%;
            background: rgba(0, 0, 0, 0.05);
            transition: all 1s ease;

            border-radius: 20px;
        }

        &:hover::after {
            content: attr(data-img);
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 98.6%;
            background: rgba(255, 0, 122, 0.05);
            transition: all 1s ease;

            border-radius: 20px;
        }
    }
}

.pic-model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition:
        opacity 0.4s ease,
        visibility 0.4s ease,
        transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;

    &.open-model {
        visibility: visible;
        opacity: 1;
        transform: scale(1);

        svg {
            position: fixed;
            top: 10px;
            right: 10px;
            width: 2rem;
            height: 2rem;
            padding: 5px;
            background-color: rgba(0, 0, 0, 0.4);
            color: #ffffff;
            cursor: pointer;
        }
    }

    img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        box-sizing: border-box;
        padding: 20px 0 20px;
        margin: 0 auto;
    }
}

@media (max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 580px) {
    .hover-state {
        width: 98.6%;
        margin: 0 0.5rem;
        width: 90%;
        font-size: 0.6rem !important;
    }

    .delete-img {
        right: initial !important;
    }

    .preview-img {
        left: initial !important;
        bottom: 2.5rem !important;
    }
}

@media (max-width: 480px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}
