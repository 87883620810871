.contact-form {
  width: 50%;
  background: #ecf0f3;
  padding: 40px 24px 35px 25px;
  border-radius: 30px;
}

.form-title {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0.5px;
  color: var(--bg-color);
}

.form-fields {
  display: table;
  width: 100%;
  padding: 15px 5px 5px 5px;
  margin-left: -1.1rem;
}

.form-fields input,
.form-fields textarea {
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  color: #555;
  padding: 20px 10px 20px 5px;
  width: 100%;
}

.form-fields textarea {
  height: 150px;
  resize: none;
}

.form-group {
  width: 39%;
  float: left;
  padding: 0px 22px;
  margin: 14px 12px;
  border-radius: 22px;
  box-shadow: inset 4px 4px 4px #cbc3d1, inset -4px -4px 4px #ffffff;
}

.form-fields .form-group:last-child {
  width: 96%;
}

.submit-button {
  padding: 18px 25px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  border: none;
  color: var(--white);
  background: #ff014f;
  box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
  transition: 0.3s all ease;
  height: fit-content;
}

.submit-button:hover {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);

  color: var(--dim-black);
  transform: translateY(-10px);
}

/* Responsive Css */

@media screen and (max-width: 940px) {
}

@media screen and (max-width: 767px) {
  .contact-form {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .contact-form {
    width: 100%;
  }

  .form-fields {
    display: table;
    width: 100%;
    padding: 15px 1px 5px 1px;
  }

  .form-group {
    width: 43%;
  }
}

@media screen and (max-width: 500px) {
  .contact-form {
  }

  .form-fields {
    display: table;
    width: 100%;
    padding: 15px 1px 5px 1px;
  }

  .form-group {
    width: 90%;
    padding: 2px 22px;
  }
}
