.sign-up {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  transform: translateX(100%);
  transition: all, 0.3s ease-out;
  z-index: 100;
}
.model-sign-up {
  width: 95%;
  margin: auto;
  display: flex;
  gap: 0.5rem;
  background: var(--Light-color);
  border-radius: 5px;
}
.input-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border: 2px solid #333;
}
.sign-up-section {
  padding: 4rem 0;
  position: relative;
}

.sign-up-text {
  text-decoration: underline;
  align-self: flex-start;
  font-size: 2rem;
  margin-left: 20px;
}
.close-sign-up {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
}
.close-sign-up:hover {
  color: var(--Colored-color);
  cursor: pointer;
}
.name-section {
  margin-top: 1rem;
}
.name-section,
.email-section,
.other-section,
.other-container {
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.email-section {
  margin: -10px 0;
}
.sign-up-img {
  display: none;
}
.input-container {
  width: 48%;
}
.Input-rapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 17px;
  margin: 1.5rem 0;
}
.input-field {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  text-indent: 8px;
  border: 1px solid gray;
  border-radius: 2px;
}
.radio-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.sign-up-btn {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
}
.sign-up-btn:disabled {
  cursor: not-allowed !important;
  font-size: 14px;
  background-color: #808080 !important;
}
.other-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.radio-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 0.5rem;
}
.radio-btn_bg {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: auto;
}

@media screen and (max-width: 370px) {
  .Input-rapper {
    font-size: 15px;
  }
}
@media screen and (min-width: 768px) {
  .model-sign-up {
    width: 100%;
    justify-content: center;
    padding: 0;
    gap: 0;
    background: transparent;
  }
  .sign-up-img {
    position: relative;
    display: block;
    width: 30%;
  }
  .sign-up-img img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .image-text-rapper {
    position: absolute;
    top: 20px;
    color: var(--Light-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .image-title {
    font-size: 2rem;
  }

  .image-text {
    font-size: 1.5rem;
    text-align: center;
  }
  .sign-up-section {
    width: 65%;
    background: var(--Light-color);
  }
  .sign-up-text {
    font-size: 3rem;
    margin-left: 25px;
  }
  .close-sign-up {
    font-size: 2rem;
  }

  .name-section {
    margin-top: 1rem;
  }

  .input-container {
    width: 46%;
  }
  .Input-rapper {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
  .input-field {
    height: 45px;
    font-size: 20px;
  }
  .sign-up-btn {
    width: 130px;
    height: 50px;
    align-self: flex-start;
  }
  .other-container {
    align-items: center;
    margin-top: 2rem;
    gap: 2rem;
  }
  .radio-btn {
    margin: 0 1rem;
  }
}
@media screen and (min-width: 920px) and (max-height: 850px) {
  .model-sign-up {
    margin-top: 40rem;
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 920px) {
  .sign-up-text {
    margin-left: 2.5rem;
  }

  .name-section,
  .email-section,
  .other-section,
  .other-container {
    width: 90%;
  }
  .input-container {
    width: 42%;
  }
  .sign-up-img {
    width: 25%;
  }
  .image-title {
    font-size: 2.2rem;
  }
  .image-text {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1280px) {
  .sign-up-img {
    width: 20%;
  }
  .name-section,
  .email-section,
  .other-section,
  .other-container {
    width: 70%;
  }
}
