.newsItem-wrap {
  display: flex;
  flex-direction: column;
  box-shadow: 3px 10px 20px 5px rgb(40 40 46 / 10%),
    0px 6px 4px rgb(92 78 78 / 25%);
  border-radius: 5px;
  padding: 1rem 1.5rem 2rem 1.5rem;
  transition: 0.5s all ease;
}

.newsItem-wrap:hover {
  transform: translateY(-10px);
  box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
}

.newsItem-img {
  position: relative;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.newsItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;

  transition: 0.5s all ease;
}

.newsItem-wrap:hover .newsItem-cover {
  transform: scale(1.03);
}

.newsItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.newsItem-description {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  font-size: 0.8rem;
  padding-right: 0.6rem;
  color: #a9a9a9;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsItem-description::before {
  position: absolute;
  content: "...";
  bottom: 0;
  left: 0;
}

.newsItem-wrap .newsItem-footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.newsItem-wrap .newsItem-link svg {
  transform: rotate(-50deg);
}

.newsItem-author {
  display: flex;
  align-items: center;
}

.newsItem-author img {
  border-radius: 50%;
  margin-right: 0.5rem;
}

.newsItem-author h6 {
  font-size: 1rem;
}

.newsItem-author p {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 600;
}
