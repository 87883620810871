.btn-shadow {
  color: var(--Colored-color);
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
  transition: all, 0.4s ease;
  cursor: pointer;
}

.section-profile {
  background: #f1f3f3 !important;
  box-shadow: inset 10px 10px 15px #cbced1, inset -10px -10px 15px #f7f7f7 !important;
}

.section-profile2 {
  background: #f1f3f3 !important;
  box-shadow: inset -10px -10px 15px #f7f7f7, inset 10px 10px 15px #cbced1 !important;
}

.profile-btn {
  position: absolute;
  top: 2.2rem;
  right: -2rem;
}
.model-profilebtn {
  display: flex;
  font-size: 1rem;
  font-weight: 600;

  padding: 10px 18px;
  gap: 5px;
  color: var(--dim-black);
  border-radius: 6px;
}

.model-profilebtn span {
  display: flex;
  font-size: 16px;
}

.model-profilebtn:hover {
  transform: translateX(-10px);
  background: var(--dimpink);
  color: var(--Light-color);
}

@media screen and (max-width: 920px) {
  .profile-btn {
    position: absolute;
    top: 2.4rem;
    right: -1rem;
  }

  .model-profilebtn {
    padding: 9px 13px 9px 17px;
    gap: 5px;

    color: var(--dim-black);
    display: flex;
    align-items: center;
  }

  .model-profilebtn span {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 875px) {
  .model-profilebtn span:nth-of-type(2) {
    display: none;
  }
}

.model__img-container {
  width: 50%;
  height: 300px;
}

@media screen and (min-width: 920px) {
  .model__img-container {
    width: 40%;
    height: 400px;
  }
}

@media screen and (max-width: 817px) {
  .model__img-container {
    width: 42%;
    height: 280px;
  }
}

.bold-text {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.semi-bold {
  font-weight: 600;
  text-transform: capitalize;
}

.model-info {
  display: flex;
  justify-content: left;
  gap: 0.5rem;
  padding: 2rem 1.2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%),
    0px 6px 4px rgb(92 78 78 / 25%);
}

.model-Info__text-content {
  position: relative;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.model-info {
  position: relative;
}

.effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.effect span {
  font-size: 50px;
  color: #e0e5eb;
  text-shadow: -8px -8px 12px rgba(255, 255, 255, 0.4),
    8px 8px 12px rgba(0, 0, 0, 0.08);
  caret-color: #262626;
  outline: none;
}
/* .model__img-container {
  width: 50%;
  height: 300px;
} */
.model__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}
.top-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.model__name {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: var(--font-size-m);
  color: var(--black);
}
.model__category {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  background: #eeee;
  color: #333;
}
.interactive-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.interactive-icon {
  background: transparent;
}
.interactive-text {
  display: none;
}
.model__location {
  margin: 0.3rem 0;
}

.model__location-icon {
  font-size: 25px;
  color: var(--Colored-color);
  margin-right: 5px;
}
.model__agency-icon {
  font-size: 25px;
  color: var(--Dark-color);
  margin-right: 5px;
}

.model__activities {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.3rem;
  font-size: 12px;
  color: #333;
}
.Icon {
  font-size: 18px;
}
.heart-icon {
  color: var(--Colored-color);
}
.follow-icon {
  color: var(--dim-black);
}
.star--icon {
  color: var(--Colored-color);
}
.insta-icon {
  color: rgb(211, 79, 228);
}

.share-icon {
  color: #333;
}
.cta-btn-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cta-btn {
  width: 70%;
  height: 55px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}
.desktop-cta {
  display: none;
}
.msg-btn {
  display: none;
}

/* sections */
.bio-section,
.photo-section,
.polaroid-section,
.video-section,
.stat-section {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 3rem;
  padding: 2rem 1.2rem;
  box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%),
    0px 6px 4px rgb(92 78 78 / 25%);
  border-radius: 10px;
}

.bio__title,
.photo__title,
.polaroid__title,
.video__title,
.stat__title {
  font-size: 0.9rem;
  color: var(--Colored-color);
}

/*bio section  */

.bio__text {
  line-height: 1.8;
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
  padding: 2rem 1.2rem;
  border-radius: 10px;
}
/* stat section */
.stat__list,
.job__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  row-gap: 2rem;
  padding: 1rem 0;
  padding-left: 1.2rem;
  margin-left: 4px;
  border-left: 4px solid var(--Dark-color);
  position: relative;
}
.job__list {
  border: 2px solid var(--Dark-color);
  padding: 3rem 1.2rem;
}
.stat__list::after,
.stat__list::before {
  content: "";
  width: 18px;
  height: 18px;
  background: var(--Dark-color);
  position: absolute;
  border-radius: 50%;
}

.stat__list::before {
  top: -15px;
  left: -11px;
}
.stat__list::after {
  bottom: -15px;
  left: -11px;
}

.stat__item,
.job__item {
  width: 45%;
  text-transform: capitalize;
  font-size: 14px;
  border-bottom: 1px solid gray;
}

.stat__comment {
  width: 100%;
  text-align: left;
  line-height: 1.8;
  font-weight: 500;
  font-size: 22px;
  margin-top: 2rem;
}

.download-btn {
  width: 75%;
  height: 55px;
  margin: auto;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  margin-top: 2rem;
}
/* photo section */

.videoList,
.imgList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1.2rem;
  align-items: center;
}
.videoList {
  gap: 1.5rem;
}

.videoItem {
  width: 45%;
  height: 100px;
  border: 2px solid #1e202c;
}
.imgItem {
  width: 47%;
  height: 180px;
}
.video {
  width: 100%;
  height: 100%;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  border: 1px solid var(--dim-black);
}

/* booking section */
.booking-section {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: 6rem 0;
  overflow: auto;
  display: block;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
}
.book-form {
  position: relative;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  padding: 4rem 2rem;
  font-size: 20px;
  font-weight: 600;
  background: var(--Light-color);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 2rem;
}

.bookform-text {
  height: 45px;
  font-size: 18px;
  font-weight: 500;
  text-indent: 10px;
  border: 2px solid var(--Dark-color);
  border-radius: 5px;
}
input:focus {
  outline: none;
}
.form__top-input {
  display: flex;
  flex-direction: column;
}
.input__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2rem;
}
.input-rapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bookform-textarea {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid var(--Dark-color);
  border-radius: 5px;
  text-indent: 10px;
  resize: none;
  outline: none;
}
.send-btn {
  width: 150px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
}
input[type="date"],
.send-btn:hover {
  opacity: 0.7;
  cursor: pointer;
}
.close-icon {
  font-size: 2rem;
  position: absolute;
  top: 3.3rem;
  right: 10%;
}
.close-icon:hover {
  color: var(--Colored-color);
  cursor: pointer;
}
.compCard-btn {
  width: 90%;
  height: 50px;
  border-radius: 25px;
}

.profile-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: gray;
  border-top: 1px solid gray;
  padding: 0.8rem 0;
}
.profile-footer:hover {
  cursor: pointer;
  color: var(--Colored-color);
}

@media screen and (max-width: 380px) {
  .model-info {
    padding: 0;
  }
  .model__location,
  .model__agency {
    font-size: 14px;
  }
}
@media screen and (max-width: 360px) {
  .imgItem {
    height: 150px;
  }
  .model__name {
    font-size: 14px;
  }
  .model__category {
    font-size: 10px;
  }
  .model__location,
  .model__agency {
    font-size: 12px;
  }
  .model__activities {
    font-size: 10px;
  }
  .cta-btn {
    font-weight: 600;
    font-size: 16px;
    gap: 0.5rem;
  }

  .stat__item,
  .job__item {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) {
  .bio__title,
  .photo__title,
  .polaroid__title,
  .video__title,
  .stat__title {
    font-size: 1.8rem;
  }
  .model-info {
    padding: 2rem 1.2rem;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%),
      0px 6px 4px rgb(92 78 78 / 25%);
  }

  .model-Info__text-content {
    width: 60%;
    gap: 1rem;
  }
  .model__category {
    font-size: 1.2rem;
    width: auto;
    padding: 0 10px;
  }
  .model__location,
  .model__agency {
    font-size: 1.5rem;
  }
  .model__location-icon,
  .model__agency-icon {
    font-size: 2.2rem;
  }
  .model__activities {
    font-size: 12px;
  }

  .interactive-section {
    justify-content: space-around;
  }
  .star--icon {
    font-size: 25px;
  }
  .Icon {
    font-size: 18px;
  }
  .interactive-text {
    display: block;
  }
  .interactive-icon {
    padding: 8px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: var(--Colored-color);
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    box-shadow: inset 4px 4px 8px #cbcbcb, inset 4px -4px 8px #ffffff;
    transition: all, 0.4s ease;
    cursor: pointer;
  }

  .interactive-icon:hover {
    transform: translateY(-10px);
  }

  .cta-btn-wrapper {
    display: none;
  }

  .cta-btn {
    width: 170px;
    height: 50px;
    position: absolute;
    top: 60px;
    right: 0;
    margin: 0;
    gap: 0;
    justify-content: space-around;
  }
  .desktop-cta {
    display: flex;
  }
  .msg-btn {
    width: 140px;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 5px;
  }

  /* photo section */
  .imgList {
    justify-content: left;
    gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .imgItem {
    width: 31%;
    height: 250px;
  }

  .bio__text {
    padding: 2rem;
  }
  /* video section */
  .videoList {
    gap: 1.2rem;
  }
  .videoItem {
    width: 23%;
    height: 120px;
  }
  /* stats section */
  .stat__list,
  .job__list {
    justify-content: left;
    row-gap: 3rem;
    column-gap: 2rem;
  }
  .stat__item,
  .job__item {
    width: 30%;
    font-size: 18px;
  }

  .compCard-btn {
    width: 40%;
    height: 50px;
  }

  /* booking-section */
  .book-form {
    width: 70%;
    padding: 3rem;
  }
  .input__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .input-rapper {
    width: 40%;
  }
}
@media screen and (min-width: 920px) {
  .bio-section,
  .photo-section,
  .polaroid-section,
  .video-section,
  .stat-section {
    padding: 3rem 1.8rem;
  }
  .bio__title,
  .photo__title,
  .polaroid__title,
  .video__title,
  .stat__title {
    font-size: 2rem;
  }
  .model-info {
    width: 95%;
    gap: 1rem;
    padding: 3rem 2rem;
  }

  .model-Info__text-content {
    width: 60%;
  }
  .model__rating {
    font-size: 20px;
  }
  .interactive-icon {
    padding: 1rem;
  }
  .model__activities {
    font-size: 1rem;
  }
  .cta-btn {
    width: 200px;
    top: 75px;
    right: 0;
    justify-content: center;
    gap: 1rem;
  }

  .imgList {
    gap: 2rem;
    row-gap: 2rem;
  }
  .imgItem {
    height: 320px;
  }

  /* stats section */

  .stat__list,
  .job__list {
    padding: 3rem 2rem;
    column-gap: 4.2rem;
  }
  .stat__list {
    padding: 1rem 2rem;
  }

  .stat__item,
  .job__item {
    width: 28%;
    font-size: 20px;
  }
  /* video section */

  .videoItem {
    width: 23%;
    height: 140px;
  }

  /* booking-section */
  .book-form {
    width: 55%;
    margin-top: 2rem;
  }

  .compCard-btn {
    width: 30%;
    height: 50px;
  }
}
@media screen and (min-width: 1280px) {
  .bio-section,
  .photo-section,
  .polaroid-section,
  .video-section,
  .stat-section {
    padding: 3rem 1.5rem;
  }
  .model-info {
    gap: 1.5rem;
    padding: 3rem 2.5rem;
  }
  /* .model__img-container {
    width: 32%;
  } */
  .model-Info__text-content {
    width: 50%;
  }

  /* photo section */
  .imgList {
    gap: 1.9rem;
    row-gap: 2rem;
  }
  .imgItem {
    width: 23%;
    height: 300px;
  }

  /* stats section */

  .stat__list,
  .job__list {
    column-gap: 1.8rem;
  }

  .stat__item,
  .job__item {
    width: 23%;
  }

  /* video section */
  .videoList {
    gap: 1.5rem;
  }

  .videoItem {
    width: 23%;
    height: 175px;
  }
  .interactive-icon {
    font-size: 18px;
  }

  /* booking-section */
  .book-form {
    width: 45%;
  }
}

.section {
  margin: 3rem auto;
  width: 95%;
}

/* links */

.links {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  justify-content: right;
}

.linkBtn {
  padding: 15px 22px;
  font-size: 1.1rem;
  border-radius: 6px;
}

.view-more-btn {
  width: 100px;
  height: 50px;
  border-radius: 6px;
}
.view-more-btn:hover,
.linkBtn:hover {
  transform: translateY(-10px);
  background: var(--dimpink);
  color: var(--Light-color);
}

@media screen and (max-width: 873px) {
  .links {
    gap: 1.2rem;
    justify-content: right;
  }

  .linkBtn {
    padding: 15px 22px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 591px) {
  .links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1035px) and (min-width: 920px) {
  .imgItem {
    width: 30%;
    height: 320px;
  }
}

@media screen and (max-width: 500px) {
  .model-info {
    display: flex;
    flex-direction: column;
  }

  .model-Info__text-content {
    width: 100%;
    padding: 0.5rem;
  }

  .model__img-container {
    width: 97%;
  }

  .interactive-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .model__namewrap svg {
    color: var(--photo);
    width: 18px;
    height: 18px;
    margin-top: 4px;
  }

  .model__names {
    font-size: 18px;
  }

  .model__activities {
    justify-content: space-evenly;
    row-gap: 0.7rem;
  }

  .model__activities p {
    font-size: 12px;
    font-weight: 600;
  }
}

@media screen and (max-width: 390px) and (min-width: 200px) {
  .model__names {
    font-size: 17px;
  }

  .model-Info__text-content {
    width: 98%;
    padding: 0.5rem;
  }

  .model__img-container {
    width: 96%;
  }

  .model-profilebtn span:nth-of-type(1) {
    display: none;
  }
}
