.modelportfolio__textwrapper {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.model__namewrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.model__names {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: var(--font-size-m);
  color: var(--black);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.model__namewrap svg {
  color: var(--photo);
  width: 20px;
  height: 20px;
}

.model__ratings {
  display: flex;
  align-items: center;
  gap: 4px;
}

.model__ratings span svg {
  width: 18px;
  height: 18px;
}

.model__ratings:nth-child(1) svg {
  color: var(--pink);
}

.model__ratings:nth-child(2) svg {
  color: var(--pink);
}

.model__ratings:nth-child(3) svg {
  padding: 0 0.5rem;
}

.model__categorys {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 5px;
}

.model__categorys div {
  display: flex;
  align-items: center;
  gap: 7px;
}

.model__categorys div span {
  font-weight: 600;
  font-size: 15px;
}

.model__categorys #agencylable > svg {
  width: 18px;
}

.model__categorys #categorylable > .lable {
  background: linear-gradient(39deg, var(--dimpink), var(--yellow));
  color: #fff;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 15px;
}

.model__categorys #agencylable > .value {
  text-transform: capitalize;
}

.model__categorys #categorylable > .value {
  width: auto;
  height: 25px;
  overflow: visible;
  text-transform: capitalize;
}

.model__locations {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 0.5rem;
}

.model__locations svg {
  width: 18px;
  height: 18px;
  color: var(--dimblack);
}

.model__locations span {
  font-weight: 600;
  height: 24px;
  font-size: 15px;
  text-transform: capitalize;
}

.model__viewprofiles {
  margin-top: 1rem;
}

.viewprofile__btn {
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  color: var(--Colored-color);
  transition: all, 0.4s ease;
  cursor: pointer;
}

.property {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  display: grid;
  place-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  box-shadow: inset -3px -3px 7px #ffffffb0,
    inset 3px 3px 5px rgba(253, 253, 253, 0.692);
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}
.single-property {
  width: 320px;
  border-radius: 1rem;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
  margin-bottom: 30px;
  overflow: hidden;
}

.column {
  padding-left: 1rem;
  padding-right: 1rem;
}
.property-thumb {
  position: relative;
  overflow: hidden;
  height: 310px;
  width: 310px;
}

.property-thumb img {
  width: 320px;
  vertical-align: middle;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 20px;
}
.single-property:hover .property-thumb img {
  transform: scale(1.06);
}

@media screen and (max-width: 1156px) {
  .single-property {
    width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .single-property {
    width: 320px;
  }
}

@media screen and (max-width: 900px) {
  .property-thumb {
    width: 95%;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .property-thumb img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
}

@media screen and (max-width: 810px) {
  .single-property {
    width: 220px;
  }

  .property-thumb {
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .property-thumb img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }

  .modelportfolio__textwrapper {
    margin-top: -0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .single-property {
    width: 85%;
  }

  .property-thumb {
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .property-thumb img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }

  .column {
    padding-bottom: 2rem;
    padding-right: 0px;
    margin-left: -0.3rem;
    /* width: 90%; */
  }

  .modelportfolio__textwrapper {
    margin-top: -0.5rem;
    padding: 1rem 1rem 1rem 1rem;
  }

  .model__names {
    font-size: 19px;
  }

  .model__namewrap > svg {
    width: 18px;
    height: 18px;
  }

  .model__ratings > span > svg {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 696px) {
  .single-property {
    width: 95%;
  }

  .property-thumb {
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .property-thumb img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }

  .column {
    /* width: 85%; */
    margin-left: 1rem;
    padding-left: 1rem;
  }

  .model__names {
    font-size: 17.5px;
  }

  .viewprofile__btn {
    padding: 8px;
    font-size: 15.5px;
    line-height: 22px;
  }
}

@media screen and (max-width: 660px) {
  .single-property {
    width: 95%;
  }

  .property-thumb {
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .property-thumb img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }

  .row {
    grid-template-columns: 1fr 1fr;
  }

  .column {
    /* width: 248px; */
  }

  .model__names {
    font-size: 16.5px;
  }

  .model__ratings > span > svg {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 480px) {
  .row {
    grid-template-columns: 1fr;
  }

  .column {
    /* width: 100%; */
    padding: 0;
    margin: 0;
  }

  .property-thumb img {
    width: 80%;
  }

  .model__names {
    font-size: 17px;
  }

  .model__ratings > span > svg {
    width: 15px;
    height: 15px;
  }
}

@media screen and (min-width: 215px) {
  .column {
    /* width: 90%; */
  }
}
