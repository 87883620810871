.comingsoon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comingsoon-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  padding: 2.8rem;
  box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%), 0px 6px 4px rgb(92 78 78 / 25%);
  border-radius: 10px;
}
.c-left,
.c-right {
  width: 50%;
}
.comingsoon-wrapper .c-left img {
  width: 100%;
}

.comingsoon-wrapper .c-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;

  border-radius: 7px;

  border-right: 2px solid var(--pink);
}

.comingsoon-wrapper .c-right p {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  padding: 0.5rem;
}

.comingsoon-wrapper .c-right h3 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--pink);

  padding: 0.3rem 0.3rem;
}
.download-Wrapper {
  display: flex;
  gap: 3rem;
}
.download--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  padding: 0.6rem 1rem;
  font-size: 1.5rem;
  border-radius: 5px;

  color: #f5f5f5;
  cursor: pointer;
}

.apple-store {
  background: rgb(35, 43, 59);
}
.apple-store:hover {
  color: rgb(35, 43, 59);
  border: 2px solid rgb(35, 43, 59);
  background: transparent;
}
.play-store {
  background: var(--pink);
}
.play-store:hover {
  color: var(--pink);
  border: 2px solid var(--pink);
  background: transparent;
}

@media screen and (max-width: 768px) {
  .comingsoon-wrapper {
    flex-direction: column;
    gap: 2rem;
    padding: 1.2rem 0.7rem;

    width: 100%;
  }

  .c-left,
  .c-right {
    width: 100%;
  }
  .c-right {
    padding: 0.6rem;
  }

  .download-Wrapper {
    display: flex;
    gap: 1rem;
  }
  .download--btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    min-width: 140px;
    padding: 0.6rem 0;
    font-size: 1rem;
    border-radius: 5px;

    color: #f5f5f5;
    cursor: pointer;
  }
  .download--btn i {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .comingsoon-wrapper {
    gap: 1rem;
    padding: 2.8rem;
  }
  .download--btn {
    gap: 1rem;

    padding: 0.6rem 1rem;
    font-size: 1.5rem;
  }
  .download--btn i {
    font-size: 2.1rem;
  }
}
