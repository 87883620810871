@import "../../../../../scss/color";
@import "../../../../../scss/mixins";
@import "../../../../../scss/utils";

#model_subscription {
  background-color: $light_gray;
  min-height: calc(100vh - 60px);
  position: relative;
  padding: 1em;

  @include mq($width: 1050px) {
    padding: 1em 2em;
  }

  h3 {
    margin-bottom: 0.6em;
  }

  #container {
    overflow-x: auto;
    border-radius: 0.4em;

    header {
      background-color: $deep_pink;
      height: 40px;
      color: $white;
      padding-top: 10px;
      font-weight: 600;
      font-size: 0.9rem;
      padding-left: 2em;
      border-radius: 0.4em 0.4em 0 0;
      min-width: 600px;
    }
  }
  #container::-webkit-scrollbar {
    height: 0.3em;

    @include mq($width: 1050px) {
      height: 0.7em;
    }
  }
  #container::-webkit-scrollbar-thumb {
    background-color: $deep_pink;
  }

  table {
    @include table_base;
    overflow: hidden;
    background-color: $white;
    border-radius: 0 0 0.5em 0.5em;
    min-width: 600px;
    margin-bottom: 1em;

    thead {
      border-bottom: 1px solid;
      th {
        font-size: 0.9rem;
      }

      tr {
        height: 70px;
      }
    }

    td {
      padding-left: 0.4em;
    }

    tr {
      text-align: center;
      height: 50px;
      border-bottom: 1px solid;
      font-weight: 600;
    }
    tfoot {
      height: 30px;
    }

    span,
    button {
      background-color: $black;
      color: $white;
      padding: 0.3em 0.6em;
      border-radius: 0.5em;
      margin-left: 0.3em;
      font-size: 0.7rem;
      font-weight: 400;
    }

    .active {
      color: $green;
    }

    button {
      font-size: 0.74rem;
      cursor: pointer;
      padding: 0.4em 1em;
      border-radius: 0.5em;
      font-weight: inherit;
    }
  }

  #bg {
    background-color: #80808067;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }
}
