$deep_pink: #ff007a;
$hot_pink: hotpink;
$red: red;
$black: black;
$dim_black: #1e202c;
$box_shadow_color: #00000040;
$white: white;
$off_white: #eef2f6;
$light_gray: #dedada;
$dark_gray: #3a3b3f;
$blue: #1665d8;
$royal_blue: #1847ed;
$light_blue: #add8e6;
$green: #008000;
$sea_green: #2e8b57;
$light_green: lightgreen;
$yellow: #fdd441;
