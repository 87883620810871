/* Join Model Begins */

.join-model-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.model-btn {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 17px 14px;
  background: var(--pink);
  color: var(--white);
  box-shadow: 0px 10px 20px rgba(255, 0, 122, 0.1);
  margin-top: 35px;
  font-family: var(--font-family-poppins);
  font-weight: 500;
  font-size: var(--font-size-s);
  letter-spacing: 0.5px;
}

.join-model-wrapper {
  background-image: url(../../../../../../public/images/home/join-community.jpg);
  position: relative;
  height: 490px;
  width: 100%;
  background-position: 50% 70%;
  background-blend-mode: overlay;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.join-model-text {
  line-height: 44px;
  margin-top: 37px;
  text-align: center;
  font-size: var(--font-size-xl);
  padding: 7px;
  font-weight: 600;
  color: var(--white);
  position: relative;
  z-index: 1;
}

.join-model-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 38px;
  padding-top: 1rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.join-model-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 18px;
}

.join-model-item svg {
  color: white;
  font-size: 1.5rem;
}

.join-model-item span {
  font-family: var(--font-family-poppins);
  font-weight: 600;
  font-size: 1.7rem;
  color: var(--smoke-white);

  text-align: center;
}

.join-model-btn {
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .join-model-icon {
    display: flex;
    flex-wrap: wrap;

    gap: 5px;
  }

  .join-model-text {
    line-height: 44px;
    margin-top: 30px;
    text-align: center;
    font-size: var(--font-size-l);
    padding: 5px;
    font-weight: 600;
    color: var(--white);
  }
}

@media screen and (max-width: 414px) {
  .join-model-icon {
    display: flex;
    flex-wrap: wrap;

    gap: 0;
  }

  .join-model-text {
    line-height: 44px;
    margin-top: 30px;
    text-align: center;
    font-size: var(--font-size-l);
    padding: 5px;
    font-weight: 600;
    color: var(--white);
  }

  .join-model-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 12px;
  }
}

@media screen and (max-width: 360px) {
  .join-model-icon {
    display: flex;
    flex-wrap: wrap;

    gap: 0;
  }

  .join-model-text {
    line-height: 44px;
    margin-top: 30px;
    text-align: center;
    font-size: var(--font-size-l);
    padding: 5px;
    font-weight: 600;
    color: var(--white);
  }

  .join-model-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px;
  }
}
