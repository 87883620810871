/* nav section */

.Acct-set-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}
.set-nav_title {
  display: none;
}
.set-nav {
  position: relative;
  width: 75%;
  min-height: 100%;
  padding: 2rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background: var(--Dark-color);
  color: var(--Light-color);
}
.set-nav_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.set-nav_item {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--Light-color);
  border-bottom: 1px solid gray;
  padding: 1.5rem 0;
}

.set-nav_list li:last-child {
  border: none;
}
.close-set {
  font-size: 2rem;
  position: absolute;
  top: 25px;
  right: 10%;
}

.set_mobile-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2rem;
  padding: 1.5rem;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
}
.mobile-nav-text {
  color: var(--Colored-color);
}
.set_mobile-nav i {
  font-size: 2rem;
}
/* content sections */
.content-container {
  width: 95%;
  margin: auto;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.set_sections-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  padding-bottom: 3rem;
}
.set_sections-title {
  font-size: 1.5rem;
  text-decoration: underline;
  color: var(--Colored-color);
}
.set_description {
  line-height: 1.5;
  font-size: 18px;
  display: flex;
  align-items: baseline;
  justify-content: left;
  gap: 0.5rem;
}
.set_sections-title-rapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.setting_btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.discard-btn,
.save-btn {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.note {
  font-size: 20px;
  color: var(--Colored-color);
}
.set_upload-btn {
  position: absolute;
  width: 70px;
  height: 70px;
  border: none;
  outline: none;
  background: var(--Light-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set_upload-btn:hover {
  background: gray;
  color: var(--Colored-color);
}
.utility-btn {
  width: 100px;
  height: 40px;
  font-size: 18px;
  background: var(--Dark-color);
  color: var(--Light-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border: none;
  border-radius: 5px;
  transition: all, 0.3s ease-out;
}

.edit-btn {
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 0.4rem;
}
.colored-hover:hover {
  color: var(--Colored-color);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .set_sections {
    display: flex;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
  }
  /* nav section */
  .Acct-set-menu {
    position: static;
    width: 30%;
    height: 100vh;
    background: var(--Dark-color);
    color: var(--Light-color);
    transform: translateX(0);
  }
  .set-nav_title {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 2px solid gray;
    font-size: 1.2rem;
  }
  .set-nav {
    width: 85%;
    min-height: auto;
    margin: 6rem auto;
    padding: 0;
    justify-content: center;
    gap: 4rem;
  }

  .set-nav_list {
    gap: 2rem;
  }
  .set_mobile-nav,
  .close-set {
    display: none;
  }

  /* main section */
  .Acct-set-main {
    width: 70%;
    height: 100vh;
    overflow: auto;
  }
  .content-container {
    padding: 4rem 0rem;
  }
  .set_sections-title {
    font-size: 1.8rem;
  }
  .set_description {
    font-size: 18px;
  }
  .set_upload-btn {
    width: 100px;
    height: 100px;
  }
  .edit-btn {
    font-size: 20px;
    font-weight: 600;
    gap: 0.5rem;
  }
}
@media screen and (min-width: 920px) {
  .set-nav_title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 920px) and (min-height: 850px) {
  .content-container {
    padding: 4rem 0rem;
  }
  .set_sections-title {
    font-size: 2rem;
  }
  .set_description {
    font-size: 22px;
  }
  .note {
    font-size: 25px;
  }
}
@media screen and (min-width: 1280px) {
  .Acct-set-menu {
    width: 25%;
  }
  .set-nav_title {
    font-size: 1.65 rem;
  }
  .Acct-set-main {
    width: 75%;
  }
  .content-container {
    padding: 4rem 0rem;
  }
  .set_sections-container {
    gap: 3rem;
  }
}
