@import "../../../scss/mixins";
@import "../../../scss/color";
@import "../../../scss/utils";

#topbar {
  height: 60px;
  @include d_flex($justify: space-between);
  padding: $big_padding;
  background-color: $deep_pink;
  position: sticky;
  top: 0;
  font-family: "Open Sans", Poppins, Helvetica, Arial, sans-serif;
  z-index: 10;

  @include mq($width: 280px) {
    padding: $md_padding;
  }

  @include mq($width: 320px) {
    padding: $big_padding;
  }

  @include mq($width: 1050px) {
    padding-inline: 1% 3%;
  }

  #left {
    @include d_flex($justify: flex-start);
    flex-grow: 0.2;
  }

  #bars {
    color: $white;
    cursor: pointer;
    @include mq($width: 280px) {
      margin-right: $small_padding;
    }
    @include mq($width: 320px) {
      margin-right: $big_padding;
    }

    @include mq($width: 768px) {
      display: none;
    }
  }
  .topbar-title {
    color: $white;
    font-size: 1.8rem;

    @include mq($width: 768px) {
      font-size: 2.2rem;
      margin-left: 2rem;
    }
  }

  #icons_holder {
    @include d_flex;
    & > * {
      margin-left: 1em;
      cursor: pointer;
    }
  }

  #notification {
    position: relative;
    @include d_flex;

    #icon {
      transform: rotate(30deg);
    }
  }

  #data {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    color: $white;
    background-color: $black;
    top: -4px;
    min-width: 20px;
    height: 20px;
    border-radius: 8px;
    text-align: center;
    padding-top: 2px;
    padding-inline: 5px;
    left: 25%;
  }
}
