.overlay-effect::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 18%,
    rgba(44, 44, 44, 0.8) 99%,
    rgba(44, 44, 44, 0.8) 100%
  );
}

.job__card__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  .job__card {
    box-shadow: 0px 11px 20px rgba(5, 16, 55, 0.1);
    display: flex;
    width: 540px;
    align-items: center;

    margin: 0 auto;
    border-radius: 1.8rem;
    gap: 1rem;

    .job__card-img {
      position: relative;
      width: 267px;
      height: 267px;
      border-radius: 1.8rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 0.8rem;
        position: relative;
      }
    }

    .job__card-info {
      margin: 0.5rem 0;
      width: 50%;

      .jobcard__card-btn {
        display: flex;
        gap: 6px;

        #job-online {
          background: #fdd441;
          color: black;
          padding: 6px 12px;
        }

        #job-offline {
          background: #f8f8f8;
          color: black;
          padding: 6px 12px;
        }

        span {
          display: inline-block;
          padding: 8px;
          background: #f8f8ff;
          border-radius: 20px;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.5px;
          color: black;
        }
      }

      .job__card-desc {
        margin: 0.5rem 0;
        h2 {
          font-size: 0.9rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          letter-spacing: 0.5px;
          margin: 1.3rem 0.4rem 1.3rem 0;
          height: 42px;
          font-weight: 700px;

          text-transform: uppercase;
          color: var(--dim-black);
        }
        div {
          display: flex;
          gap: 0.7rem;

          flex-direction: column;

          #job__card-date > span > svg {
            width: 14.5px;
            height: 14.5px;
          }

          #job__card-location > span > svg {
            width: 16.5px;
            height: 16.5px;
          }
        }

        div > div > span {
          display: flex;
          align-items: center;
          gap: 6px;
          color: var(0, 0, 0, 0.7);
          font-size: 0.8rem;

          svg {
            width: 16px;
            height: 16px;
            align-self: center;
            color: #333;
          }
        }
      }
    }

    .more-btn {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 600;

      position: relative;
      margin-top: 0.9rem;
      margin-bottom: 0.3rem;
      padding: 11px 18px !important;

      background: var(--dim-black);
    }
  }
}

@media screen and (max-width: 1441px) and (min-width: 980px) {
  .job__card__container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .job__card {
      display: flex;
      width: 90%;
    }
  }
}

@media screen and (max-width: 980px) and (min-width: 768px) {
  .job__card__container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .job__card {
      display: flex;
      width: 97%;

      .job__card-img {
        width: 90%;
        height: 250px;
      }

      .job__card-info {
        width: 60%;

        .jobcard__card-btn {
          span {
            font-size: 13px;
          }
        }

        .job__card-desc {
          div > div > span {
            display: flex;
            align-items: center;
            gap: 6px;
            color: var(0, 0, 0, 0.7);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) and (min-width: 438px) {
  .job__card__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    .job__card {
      box-shadow: 0px 11px 20px rgba(5, 16, 55, 0.1);
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 0.8rem;
      gap: 1rem;

      .job__card-img {
        position: relative;
        width: 60%;
        height: 267px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          border-radius: 0.8rem;
        }
      }

      .job__card-info {
        margin: 0.5rem 0;
        width: 50%;

        .jobcard__card-btn {
          display: flex;
          gap: 6px;

          #job-online {
            background: #fdd441;
            color: black;
            padding: 6px 12px;
          }

          #job-offline {
            background: #f8f8f8;
            color: black;
            padding: 6px 12px;
          }

          span {
            display: inline-block;
            padding: 8px;
            background: #f8f8ff;
            border-radius: 20px;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.5px;
            color: black;
          }
        }

        .job__card-desc {
          margin: 0.5rem 0;
          h2 {
            font-size: 0.9rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 1.5rem 0;

            text-transform: uppercase;
            color: var(--pink);
          }
          div {
            display: flex;
            gap: 0.7rem;

            flex-direction: column;
          }
          div > div > span {
            display: flex;
            align-items: center;
            gap: 6px;
            color: var(0, 0, 0, 0.7);
          }
        }
      }

      .more-btn {
        padding: 5px 11px;
        font-size: 0.8rem;

        margin-top: 12px;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 438px) and (min-width: 200px) {
  .job__card__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    .job__card {
      box-shadow: 0px 11px 20px rgba(5, 16, 55, 0.1);
      display: flex;
      flex-direction: column;
      width: 320px;
      padding: 0 0.5rem;
      align-items: center;
      border-radius: 0.8rem;
      gap: 1rem;

      .job__card-img {
        position: relative;
        width: 94%;
        height: 250px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          border-radius: 0.8rem;
        }
      }

      .job__card-info {
        margin: 0.5rem 0;
        width: 95%;

        .jobcard__card-btn {
          display: flex;
          gap: 6px;

          #job-online {
            background: #fdd441;
            color: black;
            padding: 6px 12px;
          }

          #job-offline {
            background: #f8f8f8;
            color: black;
            padding: 6px 12px;
          }

          span {
            display: inline-block;
            padding: 8px;
            background: #f8f8ff;
            border-radius: 20px;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.5px;
            color: black;
          }
        }

        .job__card-desc {
          margin: 0.5rem 0;
          h2 {
            font-size: 0.9rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 1.5rem 0;

            text-transform: uppercase;
            color: var(--pink);
          }
          div {
            display: flex;
            gap: 0.7rem;

            flex-direction: column;
          }
          div > div > span {
            display: flex;
            align-items: center;
            gap: 6px;
            color: var(0, 0, 0, 0.7);
          }
        }
      }

      .more-btn {
        padding: 5px 11px;
        font-size: 0.8rem;

        margin-top: 12px;
        margin-bottom: 5px;
      }
    }
  }
}
