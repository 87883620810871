.contact-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.contact-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.contact-info h2 {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0.5px;
  color: rgb(240, 240, 240);
}

.contact-text .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.contact-text .box .icon {
  min-width: 60px;
  height: 60px;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}

.contact-text .box .icon:hover {
  cursor: pointer;
  background: #30afe1;
}

.contact-text .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}

.contact-text .box .text h3 {
  font-weight: 500;
  color: var(--pink);
}

.contact-wrapper {
  display: grid;

  z-index: 1;
  margin-top: 4rem;
  margin-left: -1.3rem;
}

.contact-wrapper h2 {
  margin-left: 1.7rem;
  margin-bottom: 1.5rem;
}

.contact-social {
  display: inline-flex;
}

.contact-social .icon {
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.contact-social .icon span {
  position: relative;
  height: 60px;
  width: 60px;
  display: block;
  background: #fff;
  line-height: 72px;

  border-radius: 50%;
  text-align: center;
  z-index: 2;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.contact-social .icon span svg {
  font-size: 25px;
}

.contact-social .icon:hover span {
  color: #fff;
}

.contact-social .icon .tooltip {
  position: absolute;
  top: 0px;
  background: #fff;
  color: #fff;
  font-size: 20px;
  padding: 10px 18px;
  border-radius: 25px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.contact-social .icon:hover .tooltip {
  top: -70px;
  opacity: 1;
  pointer-events: auto;
}

.contact-social .icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.contact-social .icon:hover span,
.contact-social .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.contact-social .facebook:hover span,
.contact-social .facebook:hover .tooltip,
.contact-social .facebook:hover .tooltip:before {
  background: #3b5999;
}

.contact-social .twitter:hover span,
.contact-social .twitter:hover .tooltip,
.contact-social .twitter:hover .tooltip:before {
  background: #46c1f6;
}

.contact-social .instagram:hover span,
.contact-social .instagram:hover .tooltip,
.contact-social .instagram:hover .tooltip:before {
  background: #e1306c;
}

.contact-social .whatsapp:hover span,
.contact-social .whatsapp:hover .tooltip,
.contact-social .whatsapp:hover .tooltip:before {
  background: #25d366;
  /* background: #075e54; */
}

@media screen and (max-width: 940px) {
}

@media screen and (max-width: 767px) {
  .contact-container {
    flex-direction: column;
    gap: 2rem;
  }

  .contact-info {
    width: 100%;
  }

  .contact-text .box .icon {
    min-width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .contact-info {
    width: 100%;
  }

  .contact-social {
    padding-left: 13px;
  }

  .contact-social .icon {
    margin: 0 13px;
  }

  .contact-social .icon .tooltip {
    font-size: 20px;
    padding: 6px 14px;
  }

  .contact-social .icon span {
    height: 50px;
    width: 50px;

    line-height: 64px;
  }
}
