.Jobpostview-container {
  width: 91%;
  margin-top: 3rem;
  .Jobpost-header {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    .left {
      display: flex;
      flex-direction: column;
      gap: 25px;

      h1 {
        font-weight: 700;
        font-size: 30px;

        color: var(--dimblack);
      }

      h2 {
        font-weight: 600;
        font-size: 20px;

        color: var(--dimblack);
      }

      .left-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        align-items: center;
        margin-top: -8px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 9px 21px;
          gap: 8px;
          background: #f8f8ff;

          /* my black color */

          border-radius: 20px;
          font-size: var(--font-size-s);
          font-weight: 500;
          color: #1e202c;

          svg {
            width: 27px;
            align-self: center;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      gap: 85.5px;
      margin-top: 8px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px 21px;
        gap: 8px;
        background: #f8f8ff;
        border-radius: 20px;
        font-size: var(--font-size-s);
        font-weight: 500;
        color: #1e202c;

        svg {
          align-self: center;
          width: 27px;
        }
      }

      #post-job {
        background: #1e202c;
        color: white;
        border-radius: 20px;
        padding: 12px 24px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }
  }

  .Jobpost-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 55px;

    .jobcard {
      display: flex;

      padding: 40px 16px 24px 24px;
      gap: 32px;

      background: #ffffff;
      /* my shadow 2 */

      box-shadow: 0px 11px 20px rgba(5, 16, 55, 0.1);
      img {
        width: 100%;
      }

      .jobcard-text {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .jobcard-btn {
          display: flex;
          gap: 16px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;

            background: #f8f8ff;
            border-radius: 20px;
            font-weight: 500;
            font-size: 16px;

            letter-spacing: 0.5px;

            color: black;

            &:nth-child(2) {
              background: #fdd441;
            }

            &:nth-child(1) {
              background: #f8f8ff;
            }
          }
        }
        .jobcard-description {
          display: flex;
          flex-direction: column;

          gap: 16px;

          h2 {
            font-weight: 700;
            font-size: var(--font-size-m);
            line-height: 28px;

            color: #1e202c;
          }

          div {
            display: flex;
            align-items: center;

            svg {
              width: 20px;
              height: 20px;
              align-self: center;
            }
          }
          #location,
          #date,
          #status {
            gap: 0.7rem;
            font-weight: 500;
            font-size: var(--font-size-s);
            letter-spacing: 0.5px;
            color: #4b4244;
          }
        }

        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;

          background: #1e202c;
          border-radius: 10px;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Jobpostview-container {
    .Jobpost-header {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      .left {
        display: flex;
        flex-direction: column;
        gap: 25px;

        h1 {
          font-weight: 700;
          font-size: 20px;

          color: var(--dimblack);
        }

        h2 {
          font-weight: 600;
          font-size: 16px;

          color: var(--dimblack);
        }

        .left-btn {
          display: flex;
          flex-wrap: wrap;
          gap: 28px;
          align-items: center;
          margin-top: -8px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 15px;
            gap: 8px;
            background: #f8f8ff;

            /* my black color */

            border-radius: 20px;
            font-size: var(--font-size-s);
            font-weight: 500;
            color: #1e202c;

            svg {
              width: 20px;
              align-self: center;
            }
          }
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 85.5px;
        margin-top: 8px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6ps 15px;
          gap: 8px;
          background: #f8f8ff;
          border-radius: 20px;
          font-size: var(--font-size-s);
          font-weight: 500;
          color: #1e202c;

          svg {
            align-self: center;
            width: 20px;
          }
        }

        #post-job {
          background: #1e202c;
          color: white;
          border-radius: 20px;
          padding: 9px 20px;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

// @media screen and (max-width: 438px) and (min-width: 200px) {

// }
