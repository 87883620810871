@import "../../../../scss/mixins";
@import "../../../../scss/color";
@import "../../../../scss/utils";

.model_page {
  width: 100%;

  @include mq($width: 1050px) {
    display: flex;
    overflow: hidden;
    height: 100vh;
  }

  #nav_button {
    font-family: inherit;
    background-color: $white;
    color: $red;
    border-radius: 0.3em;
    font-weight: 600;
    cursor: pointer;

    @include mq($width: 240px) {
      font-size: 0.5rem;
      padding: 5px;
    }

    @include mq($width: 280px) {
      font-size: 0.6rem;
      padding: 0.5em;
    }

    @include mq($width: 336px) {
      font-size: 0.7rem;
      padding: 5px;
    }
  }

  main {
    width: 100%;

    @include mq($width: 1050px) {
      overflow-y: auto;
    }
  }
}
