.footer-container {
  background: var(--dim-black);
  margin-top: 4rem;
}

.footer-section {
  display: flex;

  justify-content: space-between;
  padding-top: 6rem;
}

#footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.show-on-mobile {
  display: none;
}

.hide-on-mobile {
  display: flex;
}

#footer-logo span:nth-of-type(1) {
  color: var(--white);
}

.footer {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding: 0;
}

.footer-section ul li {
  list-style: none;
  padding: 5px;
}

.footer-section ul li a {
  text-decoration: none;
  color: var(--smoke-white);
  font-size: var(--font-size-s);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.footer > h2 {
  font-size: 700;
  font-size: var(--font-size-m);
}

.footer span {
  font-size: var(--font-size-m);
  font-weight: 600;
  margin-top: 5px;
}

.footer-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  gap: 16px;
  transition: var(--transition);
}

.footer-heading:hover {
  color: var(--pink);
}

.footer-heading img {
  height: 30px;
  align-self: center;
  justify-self: center;
}

.footercontact-info {
  display: flex;
  flex-direction: column;
}

.footercontact-text {
  display: flex;
  justify-content: space-between;
}

.footercontact-info h2 {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0.5px;
  color: rgb(240, 240, 240);
}

.footercontact-text .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.footercontact-text .box .icon {
  min-width: 50px;
  height: 50px;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  font-size: 20px;
}

.footercontact-text .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}

.footercontact-text .box .text h3 {
  font-weight: 500;
  color: var(--pink);
}

.footer-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  margin-top: 140px;
  border-top: 1px solid var(--pink);
  padding: 15px;
  width: 100%;
}

.mobile-footer {
  display: none;
}

@media screen and (max-width: 1024px) {
  .footer {
    width: 140px;
  }

  .footer-logo span {
    font-size: 30px;
  }

  .footer-section a {
    width: 140px;
  }

  .footer-heading {
    width: 150px;
  }

  .footercontact-text .box {
    padding: 20px 5px;
  }

  .footercontact-text .box .icon {
    min-width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 984px) {
  .footer-heading h2 {
    font-size: 1.4rem;
  }

  .footercontact-info h2 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 870px) {
  .footercontact-text .box:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .footer-section {
    display: flex;
    justify-content: space-between;
  }

  #footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .footer {
    width: 110px;
    gap: 36px;
  }

  .footer-heading img {
    height: 27px;
  }

  .footer:nth-child(4) {
    display: none;
  }

  .footer-logo span {
    font-size: 28px;
  }

  .footer-section a {
    width: 130px;
  }

  .footer-heading {
    width: 140px;
  }

  .footercontact-text {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .footercontact-text .box {
    padding: 20px 3px;
  }

  .footercontact-text .box .icon {
    min-width: 40px;
    height: 40px;
  }

  .footercontact-text .box:nth-child(2) {
    display: none;
  }

  .footercontact-text .box .text h3 {
    font-size: 1.17rem;
  }

  .footercontact-text .box .text p {
    font-size: 0.9rem;
  }

  .footercontact-info h2 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 600px) {
  .footer-section {
    padding-top: 2rem;
    align-items: center;
  }

  .footercontact-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .footercontact-text .box:nth-child(2) {
    display: flex;
  }

  .show-on-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  .show-logo {
    overflow: hidden;
    padding: 0 18px;
    background-color: var(--dim-black);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 10px 20px 5px rgb(30 32 44 / 10%);
    border-radius: 5px;
  }

  .show-on-mobile .logo-2 span {
    font-size: 36px;
    text-align: center;
  }

  .hide-on-mobile {
    display: none;
  }

  #footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-content: space-around;
    padding-left: 1rem;
  }

  .coming-soon {
    display: none;
  }

  .footer {
    overflow: hidden;
    padding: 3rem 6rem;
    background-color: var(--dim-black);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 10px 20px 5px rgb(30 32 44 / 10%);
    border-radius: 5px;
  }

  .footer:hover {
    transform: translateY(-13px);
    transition: 0.5s all ease;
  }
  .footercontact-info {
    padding-top: 3rem;
    text-align: center;
  }

  .mobile-footer {
    display: flex;
  }

  .replaced-footer {
    display: none;
  }

  .mobilefooter_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3rem;
    place-items: center;
  }

  .mobile-footer ul li {
    padding: 3px;
  }
}

@media screen and (max-width: 414px) {
  #footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    justify-content: space-around;
    gap: 1rem;
    padding-left: 0px;
    width: 90%;
  }

  .footer {
    width: 100%;
  }

  .footercontact-info h2 {
    font-size: 1.4rem;
    text-align: center;
  }

  .footercontact-text .box {
    padding: 20px 0px;
  }

  .footercontact-text .box .text {
    margin-left: 17px;
  }

  .footercontact-text {
    width: 90%;
    margin: 0 auto;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 402px) {
  #footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    justify-content: space-around;
    gap: 1rem;
    padding-left: 1rem;
    width: 90%;
  }

  .footer {
    width: 100%;
    padding: 2rem 5rem;
  }

  .footercontact-text {
    width: 90%;
  }
}
