@import "../../../scss/color";
@import "../../../scss/mixins";
@import "../../../scss/utils";

.blog_preview_card {
  background-color: $light_gray;
  overflow: hidden;
  border-radius: $small_padding;
  width: 95%;
  height: 400px;
  border: 1px solid;

  @include mq($width: 768px) {
    width: 45%;
  }

  @include mq($width: 1050px) {
    width: 23%;
    height: 300px;
  }

  .author_img {
    width: 100%;
    height: 54%;
    overflow: hidden;
  }
  .author_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .body {
    padding: $small_padding;
    @include d_flex(flex-start, space-between, $dir: column);
    height: 26%;
  }
  .blog_info {
    width: 100%;
    @include d_flex($justify: space-between);
  }

  .blog_title {
    font-size: 0.8rem;
    font-weight: 500;
  }
  .model,
  .date {
    font-size: 0.6rem;
    font-weight: 600;
    margin: 5px;
  }
}
