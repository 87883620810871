.NewsList-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

@media screen and (max-width: 768px) {
  .NewsList-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .NewsList-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
