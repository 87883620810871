.searchBar-wrapper {
  background-color: #f8f8f8;
  width: fit-content;
  margin: 0 auto 2.8rem auto;
  padding: 0.5rem;
  border-radius: 5px;
}

.searchBar-wrapper form {
  display: flex;
  align-items: center;
  background: #ffe9e9;
}

.searchBar-wrapper input {
  outline: none;
  padding: 0.5rem 1rem;
}

.searchBar-wrapper span {
  padding-right: 0.5rem;
  cursor: pointer;
}

.searchBar-wrapper button {
  outline: none;
  padding: 0.4rem 1.4rem;
  border-radius: 5px;
  background: var(--dimpink);
  color: #fff;
}
