#notfound {
    position: relative;
    height: 100vh;
    background: linear-gradient(225deg, rgba(10, 15, 21, 0.65), 0.03%, #f9f9f9, rgba(255, 0, 123, 0.057));
    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 410px;
        width: 100%;
        text-align: center;

        .notfound-404 {
            height: 280px;
            position: relative;
            z-index: -1;

            h1 {
                font-size: 230px;
                margin: 0;
                font-weight: 900;
                position: absolute;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                background: url(../LoginSignup/Login/img/bg.jpeg) no-repeat;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                background-color: hsl(213, 35%, 6%);
            }
        }

        h2 {
            color: #000;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 40px;
            padding-top: 20px;
            margin-bottom: 20px;
        }

        p {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            margin-top: 0;
        }

        .toback-container {
            display: flex;
            align-items: center;
            gap: 1rem;
            .toback {
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #notfound {
        .notfound {
            .notfound-404 {
                height: 142px;

                h1 {
                    font-size: 112px;
                }
            }

            h2 {
                margin-top: 20px;
            }

            p {
            }

            .toback-container {
                .toback {
                }
            }
        }
    }
}

@media screen and (max-width: 442px) {
    #notfound {
        .notfound {
            .notfound-404 {
                height: 132px;

                h1 {
                    font-size: 102px;
                }
            }

            h2 {
                margin-top: 17px;
                padding-top: 17px;
            }

            p {
                padding: 0 0.4rem;
            }

            .toback-container {
                flex-direction: column;
                gap: 1.4rem;
                .toback {
                }
            }
        }
    }
}
