@import "../../../../scss/mixins";
@import "../../../../scss/color";
@import "../../../../scss/utils";

#agency_page {
  background-color: $dark_gray;
  width: 100%;

  #nav_button {
    background-color: $white;
    color: $red;
    border-radius: 0.3em;
    font-weight: 600;
    cursor: pointer;
    @include mq($width: 280px) {
      font-size: 0.6rem;
      padding: 1em 0.5em;
    }
    @include mq($width: 320px) {
      font-size: 0.9rem;
      padding: 0.7em 1em;
    }
  }

  @include mq($width: 1050px) {
    display: flex;
    overflow: hidden;
    height: 100vh;
  }

  main {
    width: 100%;

    @include mq($width: 1050px) {
      width: 85%;
      overflow-y: auto;
    }
  }
}
