.jobdetail-box {
  background-color: #fff;
  border-radius: 1.5rem;
  // box-shadow: 0 0 6px rgb(0 0 0 / 6%), 0 6px 6px rgb(0 0 0 / 6%);
  box-shadow: 0px 11px 20px rgb(5 16 55 / 10%);
  color: #1d1d1f;
  display: block;
  padding: 1.5rem;
}

.jobs-line {
  background: var(--dim-black);
}

.Jobdetail-container {
  .jobdetail-view {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    header {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;

      .titlebar {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 0.4rem;

        h1 {
          font-size: 30px;
          color: var(--dim-black);
        }

        span {
          display: flex;
          align-items: center;
          gap: 7px;
          color: var(--dim-black);
          cursor: pointer;

          svg {
            color: var(--dim-black);
            width: 15px;
            height: 15px;
          }
        }
      }

      .desc-status {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;

        #job-online {
          background: #fdd441;
          color: black;
          padding: 6px 12px;
        }

        #job-offline {
          background: #f8f8f8;
          color: black;
          padding: 6px 12px;
        }

        span {
          display: inline-block;
          padding: 8px;
          background: #f8f8ff;
          border-radius: 20px;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.5px;
          color: black;
        }
      }

      .desc-bar {
        display: flex;
        gap: 1.1rem;

        .info-wrapper {
          .info-box {
            display: flex;
            align-items: center;
            gap: 7px;

            span {
              font-size: 16.7px;
              font-weight: 500;
              color: #424242e1;
            }
          }
        }
      }

      .button-wrapper {
        button {
          color: #fff;
          font-size: 0.9rem;
          font-weight: 600;
          position: relative;
          margin-top: 0.9rem;
          margin-bottom: 0.3rem;
          padding: 11px 18px !important;
          background: var(--dim-black);
          border-radius: 6px;

          &:hover {
            background: var(--dimpink);
            color: var(--Light-color);
            transform: translateY(-10px);
          }
        }
      }
    }

    main {
      display: flex;
      margin-top: 2rem;
      gap: 2rem;

      h4 {
        font-size: 16px;
        color: rgb(0 0 0 / 85%);
        position: relative;
        margin-bottom: 6px;

        &::before {
          content: "";
          bottom: -0.5rem;

          position: absolute;
          width: 40px;
          height: 2.1898px;
          background-color: var(--dimpink);
        }
      }

      span {
        font-size: 15px;
      }

      span:nth-of-type(1) {
        font-weight: 600;
      }
      span:nth-of-type(2) {
        font-size: 14.8px;
        color: #424242e1;
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .top {
          display: flex;
          flex-direction: column;
          gap: 1.1rem;
        }

        .bottom {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 300px;

        .top,
        .bottom {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .info-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .info-box {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
        }
      }
    }

    .button-section {
      display: flex;
      align-items: center;
      margin: 1rem auto;
      button {
        color: #fff;
        font-size: 0.9rem;
        font-weight: 600;
        position: relative;
        margin-top: 0.9rem;
        margin-bottom: 0.3rem;
        padding: 11px 18px !important;
        background: var(--dim-black);
        border-radius: 6px;

        &:hover {
          background: var(--dimpink);
          color: var(--Light-color);
          transform: translateY(-10px);
        }
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      font-size: 26px;
    }
  }
}

@media screen and (max-width: 768px) {
  .Jobdetail-container {
    .jobdetail-view {
      main {
        .bottom {
          gap: 1rem;
        }
      }
    }

    footer {
      h3 {
        font-size: 23px;
      }
    }
  }
}

@media screen and (max-width: 689px) {
  .Jobdetail-container {
    .jobdetail-view {
      .desc-bar {
        display: flex;
        gap: 0.7rem;
      }
    }

    main {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100% !important ;

        .top,
        .bottom {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .desc-bar {
    flex-direction: column;
  }

  .titlebar {
    h1 {
      font-size: 21px !important;
    }
  }
}

@media screen and (max-width: 399px) {
  .Jobdetail-container {
    .jobdetail-view {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .titlebar {
        h1 {
          font-size: 21px !important;
          color: var(--dim-black);
        }
      }

      .desc-status {
        display: flex;
        gap: 6px;

        #job-online {
          background: #fdd441;
          color: black;
          padding: 6px 12px;
        }

        #job-offline {
          background: #f8f8f8;
          color: black;
          padding: 6px 12px;
        }

        span {
          display: inline-block;
          padding: 8px;
          background: #f8f8ff;
          border-radius: 20px;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.5px;
          color: black;
        }
      }

      .desc-bar {
        display: flex;
        gap: 1rem !important ;
        flex-wrap: wrap;
        flex-direction: column;

        .info-wrapper {
          .info-box {
            display: flex;
            align-items: center;
            gap: 7px;

            span {
              font-size: 15.5px !important;
              font-weight: 500;
              color: #424242e1;
            }
          }
        }
      }

      .button-wrapper {
        button {
          color: #fff;
          font-size: 0.89rem !important;
          font-weight: 600;
          position: relative;
          margin-top: 0.9rem;
          margin-bottom: 0.3rem;
          padding: 11px 18px !important;
          background: var(--dim-black);
          border-radius: 6px;

          &:hover {
            background: var(--dimpink);
            color: var(--Light-color);
            transform: translateY(-10px);
          }
        }
      }
    }

    main {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      gap: 2rem;

      .left {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        width: 100%;

        .top {
          display: flex;
          flex-direction: column;
          gap: 1.1rem;
        }

        .bottom {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;

        .top,
        .bottom {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
        }

        .info-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .info-box {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
        }
      }
    }
  }

  footer {
    h3 {
      font-size: 20px !important;
    }
  }
}

@media screen and (max-width: 348px) {
  .desc-bar {
    display: flex;
    gap: 1rem !important ;
    flex-wrap: wrap;

    .info-wrapper {
      .info-box {
        display: flex;
        align-items: center;
        gap: 7px;

        span {
          font-size: 15.5px !important;
          font-weight: 500;
          color: #424242e1;
        }
      }
    }
  }
}
