.set-payment {
  background: #f5f5f5;
  padding: 2rem 1rem;
  min-height: 100vh;
}
.set-payment-title {
  color: var(--Colored-color);
  font-size: 2rem;
  text-decoration: underline;
}
.set-payment-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.5rem;
}

.set-payment-img {
  width: 100%;
  height: 400px;
}

.set-payment-img img {
  width: 100%;
  height: 100%;
}
.set-payment-text {
  align-self: flex-start;
  font-size: 1.6rem;
}
.set-payment-text i {
  color: gray;
}

.set-payment-forms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}
.set-forms-wrapper {
  width: 100%;
}
.set-payment-btn {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--Colored-color);
  color: var(--Light-color);
  border-radius: 10px;
  cursor: pointer;
}

/* set payment form section  */

.set-payment-fieldset {
  border: 2px solid var(--Dark-color);
  background: var(--Light-color);
  border-radius: 5px;
  padding: 1rem 1.2rem;
  margin: 1rem 0;
}
.set-payment-legend {
  padding: 5px;
  font-size: 1.5rem;
  color: var(--Dark-color);
  font-weight: 500;
}

.set-payment-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.set-payment-label-text {
  font-size: 1.3rem;
  color: var(--Dark-color);
  font-weight: 500;
  cursor: pointer;
}

.label-3 {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid var(--Dark-color);
}

.label-3 input {
  width: 80%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  text-indent: 8px;
  background: #f5f5f5;
}
.label-3-icon {
  color: var(--Colored-color);
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 10px;
  cursor: pointer;
}

.set-payment-btn-2 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
}

.set-payment-save,
.set-payment-discard {
  width: 75px;
  height: 35px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all, 0.4s ease-out;
}

.set-payment-discard {
  background: var(--Dark-color);
  color: var(--Light-color);
}
.set-payment-discard:hover {
  color: var(--Dark-color);
  background: var(--Light-color);
  border: 3px solid var(--Dark-color);
}
.set-payment-save {
  background: var(--Colored-color);
  color: var(--Light-color);
}
.set-payment-save:hover {
  color: var(--Colored-color);
  background: var(--Light-color);
  border: 3px solid var(--Colored-color);
}

@media screen and (max-width: 360px) {
  .set-payment {
    padding: 2rem 0.5rem;
  }
  .set-payment-profile {
    padding: 1.5rem 1rem;
  }

  .set-payment-text i {
    font-size: 1.4rem;
  }

  .set-payment-btn {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 380px) {
  .set-payment-img {
    height: 320px;
  }
}
@media screen and (min-width: 768px) {
  .set-payment {
    padding: 2rem;
  }
  .set-payment-title {
    font-size: 2.5rem;
  }

  .set-payment-text {
    font-size: 2.2rem;
  }
  .set-payment-text i {
    font-size: 1.8rem;
  }

  .set-payment-forms {
    gap: 3rem;
    padding: 2rem 1rem;
  }

  .set-payment-btn {
    padding: 1rem 1.5rem;
    font-size: 1.8rem;
  }

  /* set payment form section  */
  .set-payment-fieldset {
    padding: 2.5rem;
  }

  legend {
    font-size: 2rem;
  }

  .set-payment-label {
    margin: 1.2rem 0;
  }

  .set-payment-label-text {
    font-size: 1.6rem;
  }

  .label-3 {
    height: 50px;
  }

  .label-3 input {
    font-size: 1.4rem;
  }
  .label-3-icon {
    font-size: 1.5rem;
  }
  .set-payment-btn-2 {
    padding-top: 2.5rem;
  }
  .set-payment-save,
  .set-payment-discard {
    width: 120px;
    height: 45px;
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 920px) {
  .set-payment {
    padding: 4rem;
  }
  .set-payment-img {
    width: 100%;
    height: 500px;
  }
  .set-payment-text {
    font-size: 3rem;
  }
  .set-payment-text i {
    font-size: 2.2rem;
  }
  .set-payment-btn {
    padding: 0.6rem 1.5rem;
    font-size: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .set-payment-profile {
    display: flex;
    flex-direction: row;
    padding: 2rem 1rem;
  }
  .set-payment-img {
    width: 40%;
    height: 400px;
  }

  .set-payment-text {
    align-self: flex-end;
    font-size: 2.2rem;
  }
  .set-payment-text i {
    font-size: 1.8rem;
  }
}
