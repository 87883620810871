




.alert-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.success-icon {
  color: var(--Colored-color);
  font-size: 2rem;
  margin-left: 5px;
}
.alert-box {
  position: relative;
  width: 90%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 1rem 1.5rem;
  text-align: center;
}
.alert-title {
  padding: 0 1rem;
}
.alert-text {
  font-size: 18px;
  line-height: 1.6;
}
.alert-btn {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.del-alert-btn {
  width: 85px;
  height: 40px;
  border: none;
  border-radius: 5px;
  outline: none;
}
.yes-btn {
  background: var(--Colored-color);
  color: var(--Light-color);
  cursor: pointer;
  transition: all, 0.4s ease-out;
}
.yes-btn:hover {
  background: var(--Light-color);
  color: var(--Colored-color);
  border: 3px solid var(--Colored-color);
}
.cancel-btn {
  background: var(--Dark-color);
  color: var(--Light-color);
  cursor: pointer;
  transition: all, 0.4s ease-out;
}
.cancel-btn:hover {
  color: var(--Dark-color);
  background: var(--Light-color);
  border: 3px solid var(--Dark-color);
}
.close-alert2 {
  color: var(--Colored-color);
  font-size: 2rem;
  position: absolute;
  top: 20px;
  right: 5%;
}
.close-alert2:hover {
  color: var(--Light-color);
  cursor: pointer;
}
.close-alert {
  font-size: 1.6rem;
  position: absolute;
  top: 20px;
  right: 5%;
}
.required-icon_rapper {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.required-icon {
  color: var(--Colored-color);
  font-size: 8px;
  transition: 1s cubic-bezier(0.4, 0.1, 0.4, 1) !important;
}
.valid-icon {
  color: var(--icon-check-color);
  transition: 1s cubic-bezier(0.4, 0.1, 0.4, 1) !important;
  font-size: x-small;
}
.error-text {
  color: var(--Colored-color);
  font-size: 12px;
  margin: 0.5rem 0;
}

@media screen and (min-width: 768px) {
  .alert-box {
    width: 70%;
    padding: 1rem 3rem;
  }
  .alert-text {
    font-size: 20px;
    line-height: 1.6;
    padding: 0 2rem;
  }
  .alert-title {
    padding: 0 3rem;
  }
}

@media screen and (min-width: 920px) {
  .alert-box {
    width: 50%;
  }
  .alert-text {
    padding: 0 1.2rem;
  }
  .alert-title {
    padding: 0 2rem;
  }
}

@media screen and (min-width: 1230px) {
  .alert-box {
    width: 40%;
  }
  .alert-text {
    padding: 0rem;
  }
  .alert-title {
    padding: 0 1rem;
  }
}
