.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: 50% 50%;
  background-blend-mode: overlay;
}

@media screen and (max-width: 940px) {
  .contact {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  .contact {
    padding: 15px 30px;
  }
}
