.featured-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.featured-model-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%),
    0px 6px 4px rgb(92 78 78 / 25%);
  border-radius: 10px;
}

.featured-desc {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}
.featured-model-category {
  font-size: 15px;
  font-weight: 500;
}

#activeCategory {
  color: var(--white);
  background: var(--pink);
}

.featured-model-slider {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  gap: 2rem;
  padding: 2rem;
  width: 100%;
  height: 100%;

  border-radius: 10px;

  position: relative;
}

.featured-model-item {
  display: flex;
  flex-direction: column;
  position: relative;

  transition: 0.5s;
}

.featured-model-item:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

.featured-img img {
  /* width: 262px;
  height: 316px; */
  width: 100%;
}

.featured-model-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: linear-gradient(360deg, black, transparent);
  width: 100%;
  bottom: 7px;
  padding: 1rem;
  border-radius: 40px 0 27px 27px;
}

.featured-model-text h2 {
  font-weight: 700;
  font-size: var(--font-size-m);
  text-transform: capitalize;
  color: #f4f4f4;
  line-height: 28px;
}

.featured-model-text h4 {
  font-weight: 500;
  font-size: 15px;
  color: var(--white);
  line-height: 23px;
  margin-top: 12px;
  letter-spacing: 0.5px;
}

.featured-model-btn {
  background: var(--pink);
  color: white;
  font-weight: 500;
  border-radius: 30px;
  margin-bottom: 2rem;
}

.featured-model-btn:hover {
  color: var(--pink);
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
}

@media screen and (max-width: 1024px) {
  .featured-model-slider {
    grid-template-columns: repeat(3, 1fr);
  }
  .featured-img img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .featured-model-slider {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .featured-model-slider {
    grid-template-columns: repeat(1, 1fr);
  }

  .featured-desc {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
}
