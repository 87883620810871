.privacy-container {
  min-width: 600px;
  max-width: 1250px;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.8;
}
.privacy-container h1 {
  text-decoration: underline;
}
.privacy-list {
  padding: 10px 0;
}
.privacy-list li {
  list-style: disc;
  margin-left: 2rem;
  padding: 5px 0;
}
.privacy-data-share {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}
.privacy-link {
  color: rgb(0, 191, 255);
}
.privacy-link:hover {
  color: rgb(255, 47, 0);
}
