/* Main section */
/* category section */

.shadow-fit {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
}

.category-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 2rem;
  margin-top: 2rem;
}

.categories-wrapper {
  display: flex;
}

.categories-slide {
  cursor: grab;
  width: 100%;
}

.categories-item {
  width: 240px;
  height: 340px;
  position: relative;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories-item img {
  width: 100%;
  height: 308px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #000000;
  border-radius: 20px;
}

.categories-item span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1315px) {
  .categories-slide:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 1060px) {
  .categories-slide:nth-child(4) {
    display: none;
  }
}

@media screen and (max-width: 785px) {
  .categories-item {
    width: 210px;
    height: 310px;
  }

  .categories-item img {
    height: 270px;
  }

  .category-heading {
    font-size: 25px;
  }

  /* .categories-item {
    width: 100%;
  }

  .categories-item img {
    border-radius: 0px;
    object-fit: contain;
    border: 1px solid var(--dimpink);

    height: 320px;

  } */
}

@media screen and (max-width: 705px) {
  .categories-slide:nth-child(4) {
    display: none;
  }

  .categories-item {
    width: 200px;
    height: 200px;
  }

  .categories-item img {
    height: 250px;
  }
}

@media screen and (max-width: 664px) {
  .categories-slide:nth-child(2) {
    display: none;
  }

  .categories-item {
    width: 90%;
    height: 200px;
  }

  .categories-item img {
    height: 250px;
  }

  .category-heading {
    font-size: 24px;
  }
}

.item__btn:hover {
  transform: translateY(-10px);
  background: var(--dimpink);
  color: var(--Light-color);
}

/* page numbering section */
.pageNumber-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 4rem 0;
}
.pageNumber-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.pageNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}
.page {
  width: 30px;
  height: 30px;
  background: var(--Dark-color);
  color: var(--Light-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.next-page,
.prev-page {
  border: none;
  outline: none;
  background: transparent;
  transition: all 0.2s ease-out;
}

.next-page:hover {
  padding-left: 10px;
  color: var(--Colored-color);
}
.prev-page:hover {
  padding-right: 10px;
  color: var(--Colored-color);
}
.more-page {
  font-size: 1.5rem;
  font-weight: 600;
}
.page-of-pages {
  font-size: 18px;
  font-weight: 600;
}

/* @media screen and (max-width: 340px) {
  .input-box {
    width: 70%;
  }
  .filter__btn {
    width: 120px;
  }

  .filter-list {
    width: 120px;
  }
} */

@media screen and (min-width: 450px) {
  /* main and contents */

  .pageNumber-container {
    gap: 2rem;
  }
}

@media screen and (min-width: 400px) {
  .category-heading {
    font-size: 30px;
  }
}
