@import "../../scss/color";
@import "../../scss/mixins";
@import "../../scss/utils";

#model_card {
  position: relative;
  overflow: hidden;
  width: 240px;
  min-width: 240px;

  //   max-height: 340px;
  border: 1px solid;
  border-radius: 0.25em;
  @include mq($width: 1050px) {
    display: flex;
    flex-direction: column;
    background-color: $white;
    height: 50%;
    min-height: unset;
    // width: 100%;
    transition: all 0.4s ease-out;
  }
}

#model_card:hover#model_card #img_holder > img {
  transform: scale(1.1);
}

#model_card #img_holder {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

#model_card #img_holder > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s ease-out;
}

#card_body {
  width: 100%;
  background-color: $white;
  padding-left: $small_padding;
}

#model_card #title {
  margin-top: 0.4em;
  color: $sea_green;
  font-weight: 700;
  font-size: 0.8rem;
}

#model_card #name {
  font-weight: 700;
  font-size: 1.1rem;
  margin-right: 0.2em;
}

#model_card #address,
#model_card #categories {
  font-size: 0.8rem;
  font-weight: 600;
  @include d_flex($justify: flex-start);
}
#model_card #address span {
  display: inline-block;
  margin-left: 0.25em;
}

#card_btn {
  color: $white;
  background-color: $dim_black;
  padding: 0.4em 1em;
  border-radius: 0.2em;
  display: block;
  cursor: pointer;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  margin-inline: auto 0.5em;
  transition: all 0.8s ease;
  margin-block: $md_padding;
}
