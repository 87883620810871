.jobCard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; /*...*/
}

.jobContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: yellow;
  width: 180px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.jobDetails {
  margin-left: 15px;
  margin-right: 15px;
}

.jobDetails p {
  color: ;
  font-size: 14px;
}

.jobDetails h4 {
  font-size: 15px;
}

.jobImg {
  width: 150px;
  height: 140px;
  margin: 10px auto;
  border-radius: 7px;
}

.jobBtn {
  background: black;
  color: white;
  height: 25px;
  font-size: 12px;
  padding: 5px;
  border-radius: 7px;
  margin: 5px 0;
}

@media screen and (max-width: 1020px) {
  .jobCard {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    background: rgb(238, 234, 234);
  }
  .jobContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: yellow;
    width: 250px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
