.wallet-home-container {
  background: #f5f5f5;
  padding: 0.8rem;
  min-height: 100vh;
}
.wallet-top-text {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  padding: 1rem;
}
.wallet-title-text {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--Colored-color);
}
.balance-section {
  border-radius: 20px;
  padding: 1.3rem;
  background: var(--Light-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.balance-top-text {
  display: flex;
  justify-content: space-between;
}
.balance-title {
  font-size: 1.4rem;
}
.balance-more-icon {
  font-size: 1.8rem;
  cursor: pointer;
}
.balance-figure {
  font-size: 2rem;
  font-weight: 600;
}
.balance-credit {
  display: flex;
  justify-content: left;
  gap: 3rem;
}
.credit,
.debit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}

.credit-icon {
  font-size: 2rem;
  font-weight: bold;
  color: #07ab28f5;
}
.credit-figure {
  font-size: 20px;
  font-weight: 600;
}
.credit-text {
  color: #07ab28f5;
}

.debit-icon {
  font-size: 2rem;
  font-weight: 900;
  color: #d40707;
}

.debit-figure {
  font-size: 20px;
  font-weight: 600;
}
.debit-text {
  color: #d40707;
}

.transaction-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0.6rem 0;
  padding-top: 1.5rem;
}
.withdraw-btn,
.transfer-btn {
  width: 100px;
  height: 38px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  transition: all, 0.4s ease-out;
}

.transfer-btn {
  background: var(--Colored-color);
  color: var(--Light-color);
}
.transfer-btn:hover {
  color: var(--Colored-color);
  background: var(--Light-color);
  border: 3px solid var(--Colored-color);
}
.withdraw-btn {
  background: var(--Dark-color);
  color: var(--Light-color);
}
.withdraw-btn:hover {
  color: var(--Dark-color);
  background: var(--Light-color);
  border: 3px solid var(--Dark-color);
}

.transaction-list {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.no-transaction {
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
}

.transaction-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.transaction-top-text h3 {
  font-size: 1.4rem;
}
.transaction-view-all {
  color: var(--Colored-color);
  font-size: 18px;
  cursor: pointer;
}

.transaction-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem 0;
  border-top: 1px solid #bbbb;
  text-transform: capitalize;
  cursor: pointer;
}
.transaction-avatar {
  width: 40px;
  height: 40px;
}
.transaction-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.transaction-amount,
.transaction-title {
  font-size: 1.1rem;
}
.transaction-time,
.transaction-sub-title {
  color: gray;
}

.transaction-text {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transaction-amount-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

/* transaction details section  */

.transaction-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem;
  background-color: #d6d5d5;
  color: #6e6b6b;
  border-top: 2px solid #6e6b6b;
  border-bottom: 2px solid #6e6b6b;
  text-transform: capitalize;
  line-height: 1.8;
}

.transaction-details-top {
  border: 1px solid#fff;
  padding: 5px;
  color: var(--Dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.details-title,
.details-status {
  font-size: 18px;
  font-weight: 500;
}
.details-amount {
  font-size: 1.7rem;
  font-weight: 600;
}
.transaction-details-main {
  border: 1px solid#fff;
  padding: 10px;
}
.transaction-details-main li {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  margin: 4px 0;
}
.details-param {
  text-align: end;
  color: var(--Dark-color);
}
.form-modal-section {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  transform: translateX(100%);
  transition: all, 0.3s ease-out;
  z-index: 100;
}
@media screen and (max-width: 345px) {
  .wallet-home-container {
    padding: 0.5rem;
  }
  .transaction-top-text h3 {
    font-size: 1.2rem;
  }
  .balance-section {
    padding: 1rem;
  }
  .transaction-buttons {
    gap: 0.8rem;
  }
  .transaction-view-all {
    color: var(--Colored-color);
    font-size: 16px;
    cursor: pointer;
  }
  .transaction-amount,
  .transaction-title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .wallet-home-container {
    padding: 2rem;
  }
  .wallet-top-text {
    font-size: 3rem;
  }
  .wallet-title-text {
    font-size: 3rem;
  }
  .balance-section {
    padding: 2rem;
  }

  .balance-title {
    font-size: 2rem;
  }
  .balance-more-icon {
    font-size: 2.2rem;
  }
  .balance-figure {
    font-size: 3rem;
  }
  .credit,
  .debit {
    gap: 1rem;
  }

  .credit-icon,
  .debit-icon {
    font-size: 3rem;
  }

  .credit-figure,
  .debit-figure {
    font-size: 2.2rem;
  }
  .credit-text,
  .debit-text {
    font-size: 1.5rem;
  }

  .transaction-buttons {
    justify-content: left;
    gap: 6rem;
    padding-top: 1.8rem;
  }
  .withdraw-btn,
  .transfer-btn {
    width: 150px;
    height: 60px;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .transaction-top-text {
    padding: 2rem 0;
    padding-bottom: 1rem;
  }
  .transaction-top-text h3 {
    font-size: 2rem;
  }
  .transaction-view-all {
    font-size: 1.6rem;
  }
  .transaction-item {
    gap: 10px;
    padding: 1.5rem 0;
    border-top: 2px solid #bbbb;
  }
  .transaction-avatar {
    width: 80px;
    height: 80px;
  }

  .transaction-title {
    font-size: 1.6rem;
  }

  .transaction-time,
  .transaction-sub-title {
    font-size: 1.4rem;
    color: gray;
  }
  .transaction-amount {
    font-size: 1.8rem;
  }

  /* transaction details section  */

  .transaction-details {
    padding: 1.2rem;
  }

  .details-title,
  .details-status {
    font-size: 1.8rem;
  }
  .details-amount {
    font-size: 3rem;
  }

  .transaction-details-main li {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 920px) {
}
@media screen and (min-width: 1230px) {
  .wallet-home-container {
    padding: 2rem 3rem;
    padding-top: 1rem;
  }
  .wallet-top-text {
    font-size: 2rem;
  }
  .wallet-title-text {
    font-size: 2.5rem;
  }
  .balance-section {
    padding: 1.5rem;
  }

  .balance-title {
    font-size: 1.5rem;
  }
  .balance-more-icon {
    font-size: 2rem;
  }
  .balance-figure {
    font-size: 2.5rem;
  }
  .credit,
  .debit {
    gap: 1rem;
  }

  .credit-icon,
  .debit-icon {
    font-size: 2.5rem;
  }

  .credit-figure,
  .debit-figure {
    font-size: 1.8rem;
  }
  .credit-text,
  .debit-text {
    font-size: 1.5rem;
  }

  .withdraw-btn,
  .transfer-btn {
    width: 125px;
    height: 50px;
    font-size: 1.5rem;
  }

  .transaction-top-text {
    padding: 2rem 0;
    padding-bottom: 1rem;
  }
  .transaction-top-text h3 {
    font-size: 1.6rem;
  }
  .transaction-view-all {
    font-size: 1.3rem;
  }
  .transaction-item {
    padding: 1.2rem 0;
  }
  .transaction-avatar {
    width: 70px;
    height: 70px;
  }

  .transaction-title {
    font-size: 1.3rem;
  }

  .transaction-time,
  .transaction-sub-title {
    font-size: 1rem;
  }
  .transaction-amount {
    font-size: 1.4rem;
  }
}
