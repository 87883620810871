@import "../../../scss/color";

#background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000038;
  z-index: 20;
}
