.imgs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.imgs img{
    width: 250px;
    /* margin-right: 100px; */
}

@media screen and (max-width: 480px) {
    .imgs img{
        width: 130px;
        /* margin-right: 10px; */
    }
/*         
    .imgs{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    } */
}

@media screen and (max-width: 820px) {
    .imgs img{
        width: 200px;
        /* margin-right: 10px; */
    }
        
    .imgs{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 1020px) {
    .imgs img{
        width: 250px;
        margin-right: 10px;
    }
        
    .imgs{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}